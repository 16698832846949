import expand from 'expand-object';

export enum SORT_DIRECTIONS {
  ascend = 'ASC',
  descend = 'DESC',
}

export function buildSortClause<T>(sort: string): T[] {
  return expand(
    sort
      .replace('ascend', SORT_DIRECTIONS.ascend)
      .replace('descend', SORT_DIRECTIONS.descend)
  );
}
