import { useState, useEffect, useCallback } from 'react';
import storage from '@/storage';

interface StorageHandler extends Array<any> {
  0: any;
  1: (newValue: any) => void;
  2: () => void;
}

/**
 * useStorage hook
 * Tracks a value within storage and updates it
 * @param {string} key - Key of the storage object
 * @param {any} initialValue - Default initial value
 */
function useStorage(key: string, initialValue: any = null): StorageHandler {
  const [value, setValue] = useState(getValueFromLocalStorage());

  function init() {
    const valueLoadedFromLocalStorage = getValueFromLocalStorage();
    if (
      initialValue &&
      (valueLoadedFromLocalStorage === null ||
        valueLoadedFromLocalStorage === 'null')
    ) {
      set(initialValue);
    }
  }

  function getValueFromLocalStorage() {
    const value = storage.getItem(key);

    return value && parseToPrimitive(value);
  }

  function parseToPrimitive(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value.toString();
    }
  }

  function saveValueToLocalStorage(k: string, v: any) {
    return storage.setItem(k, v);
  }

  function set(newValue: any) {
    setValue(newValue);
    saveValueToLocalStorage(key, newValue);
  }

  const listen = useCallback((e: StorageEvent) => {
    if (e.storageArea === storage && e.key === key) {
      setValue(e.newValue);
    }
  }, []);

  function remove() {
    setValue(null);
    storage.removeItem(key);
  }

  //initialize
  useEffect(() => {
    init();
  }, []);

  // listen for changes across tabs
  useEffect(() => {
    window.addEventListener('storage', listen);
    return () => {
      window.removeEventListener('storage', listen);
    };
  }, []);

  return [value, set, remove];
}

export { useStorage };
