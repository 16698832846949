import Cookies from 'js-cookie';
import { toString } from 'lodash';

function getItem(property: string): string | null | undefined {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(property);
  }

  return Cookies.get(property);
}

function setItem(key: string, value: string | string[]): string | undefined | void {
  if (typeof window !== 'undefined') {
    return window.localStorage.setItem(key, toString(value));
  }

  return Cookies.set(key, value);
}

function removeItem(key: string): string | undefined | void {
  if (typeof window !== 'undefined') {
    return window.localStorage.removeItem(key);
  }

  return Cookies.remove(key);
}

export default {
  getItem,
  setItem,
  removeItem,
};
