import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  Uuid: any;
};




export type AccountDto = {
  __typename?: 'AccountDto';
  isGrowFlow: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  isSandbox: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  isStandAlone: Scalars['Boolean'];
  complianceCredential?: Maybe<ComplianceCredentialDto>;
  organization?: Maybe<OrganizationDto>;
  vendor?: Maybe<VendorDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type AccountDtoConnection = {
  __typename?: 'AccountDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AccountDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AccountDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountDtoEdge = {
  __typename?: 'AccountDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AccountDto;
};

export type AccountRoleDto = {
  __typename?: 'AccountRoleDto';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
};

export type AccountUserDto = {
  __typename?: 'AccountUserDto';
  account?: Maybe<AccountDto>;
  user?: Maybe<UserDto>;
  isDisabled: Scalars['Boolean'];
  homeModule?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<AccountRoleDto>>>;
};

export type AllAccountDto = {
  __typename?: 'AllAccountDto';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  complianceCredential?: Maybe<ComplianceCredentialDto>;
  vendor?: Maybe<AllVendorDto>;
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
};

export type AllOrganizationDto = {
  __typename?: 'AllOrganizationDto';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePlanKey?: Maybe<Scalars['String']>;
  accountsCount: Scalars['Int'];
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  organizationStatus: OrganizationStatus;
  accounts?: Maybe<Array<Maybe<AllAccountDto>>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type AllOrganizationDtoConnection = {
  __typename?: 'AllOrganizationDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AllOrganizationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AllOrganizationDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AllOrganizationDtoEdge = {
  __typename?: 'AllOrganizationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AllOrganizationDto;
};

export type AllUserDto = {
  __typename?: 'AllUserDto';
  id?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isGrowFlow: Scalars['Boolean'];
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<UserRoleDto>>>;
};

export type AllVendorDto = {
  __typename?: 'AllVendorDto';
  jurisdiction?: Maybe<JurisdictionDto>;
  name?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
};

export type CcrsCsvUploadDto = {
  __typename?: 'CcrsCsvUploadDto';
  csvLink?: Maybe<Scalars['String']>;
  csvErrorGoogleCloudLink?: Maybe<Scalars['String']>;
  resultsJsonLink?: Maybe<Scalars['String']>;
  csvUploadImageLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportGeneratedAt: Scalars['DateTime'];
  csvFileName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  csvErrorLink?: Maybe<Scalars['String']>;
  numberOfRecords: Scalars['Int'];
  numberOfErrorRecords?: Maybe<Scalars['Int']>;
  errorType?: Maybe<CcrsCsvUploadErrorType>;
  parentCcrsCsvUploadId?: Maybe<Scalars['Int']>;
  parentCcrsCsvUpload?: Maybe<CcrsCsvUploadDto>;
  childCcrsCsvUploads?: Maybe<Array<Maybe<CcrsCsvUploadDto>>>;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadDto>;
  ccrsManifestUploadId?: Maybe<Scalars['Int']>;
  primaryManifestCcrsCsvUpload?: Maybe<CcrsCsvUploadDto>;
  primaryManifestCcrsCsvUploadId?: Maybe<Scalars['Int']>;
  licenseNumber?: Maybe<Scalars['String']>;
  applicationScope: CcrsApplicationScope;
  isSandBox: Scalars['Boolean'];
  status: SyncStatus;
  statusName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  processingDuration: Scalars['Long'];
  log?: Maybe<Scalars['String']>;
  logLink?: Maybe<Scalars['String']>;
  logDirectoryLink?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type CcrsCsvUploadDtoConnection = {
  __typename?: 'CcrsCsvUploadDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CcrsCsvUploadDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CcrsCsvUploadDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CcrsCsvUploadDtoEdge = {
  __typename?: 'CcrsCsvUploadDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CcrsCsvUploadDto;
};

export type CcrsManifestUploadDto = {
  __typename?: 'CcrsManifestUploadDto';
  manifestPdfLink?: Maybe<Scalars['String']>;
  resultsJsonLink?: Maybe<Scalars['String']>;
  manifestUploadImageLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  manifestLink?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  payLoad?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  applicationScope: CcrsApplicationScope;
  isSandBox: Scalars['Boolean'];
  status: SyncStatus;
  statusName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  processingDuration: Scalars['Long'];
  log?: Maybe<Scalars['String']>;
  logLink?: Maybe<Scalars['String']>;
  logDirectoryLink?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type CcrsManifestUploadDtoConnection = {
  __typename?: 'CcrsManifestUploadDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CcrsManifestUploadDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CcrsManifestUploadDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CcrsManifestUploadDtoEdge = {
  __typename?: 'CcrsManifestUploadDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CcrsManifestUploadDto;
};

export type ComplianceCredentialDto = {
  __typename?: 'ComplianceCredentialDto';
  id: Scalars['Int'];
  isSandbox: Scalars['Boolean'];
  complianceId?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type ComplianceProviderDto = {
  __typename?: 'ComplianceProviderDto';
  id: Scalars['Int'];
  shortName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DisposalDto = {
  __typename?: 'DisposalDto';
  conversionId?: Maybe<Scalars['Int']>;
  wasteInventoryId?: Maybe<Scalars['Int']>;
  inventoryId?: Maybe<Scalars['Int']>;
  typeOfInventoryDestroyReasonId?: Maybe<Scalars['Int']>;
  typeOfPlantDestroyReasonId?: Maybe<Scalars['Int']>;
  strain?: Maybe<StrainDto>;
  reason?: Maybe<MjfDisposalReasonType>;
  method?: Maybe<Scalars['String']>;
  disposalCert?: Maybe<Scalars['String']>;
  source?: Maybe<MjfDisposalSourceType>;
  isWet: Scalars['Boolean'];
  quantity: Scalars['Decimal'];
  typeOfPlantWasteMethodId?: Maybe<Scalars['Int']>;
  room?: Maybe<RoomDto>;
  batchId?: Maybe<Scalars['Int']>;
  plantId?: Maybe<Scalars['Int']>;
  holdStartsAt?: Maybe<Scalars['DateTime']>;
  holdEndsAt?: Maybe<Scalars['DateTime']>;
  isDisposed: Scalars['Boolean'];
  disposalAt?: Maybe<Scalars['DateTime']>;
  gFBatchId: Scalars['Int'];
  wasteInventoryComplianceId?: Maybe<Scalars['String']>;
  unit?: Maybe<UnitOfMeasure>;
  explanation?: Maybe<Scalars['String']>;
  lastPrinted?: Maybe<Scalars['DateTime']>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type DisposalDtoConnection = {
  __typename?: 'DisposalDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DisposalDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DisposalDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DisposalDtoEdge = {
  __typename?: 'DisposalDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DisposalDto;
};

export type DotNetAssemblyDto = {
  __typename?: 'DotNetAssemblyDto';
  name?: Maybe<Scalars['String']>;
  imageRuntimeVersion?: Maybe<Scalars['String']>;
};

export type EmployeeDto = {
  __typename?: 'EmployeeDto';
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<UserDto>;
  complianceId?: Maybe<Scalars['String']>;
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
  vehicleId?: Maybe<Scalars['Int']>;
  driversLicense?: Maybe<Scalars['String']>;
  dateOfHire?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type EmployeeDtoConnection = {
  __typename?: 'EmployeeDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<EmployeeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EmployeeDtoEdge = {
  __typename?: 'EmployeeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmployeeDto;
};

export type FileModel = {
  __typename?: 'FileModel';
  data?: Maybe<Scalars['String']>;
};

export type FilterOptionItem = {
  __typename?: 'FilterOptionItem';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type HarvestDto = {
  __typename?: 'HarvestDto';
  failure?: Maybe<HarvestDto>;
  id?: Maybe<Scalars['Int']>;
  harvestPlants?: Maybe<Array<Maybe<HarvestPlantDto>>>;
  name?: Maybe<Scalars['String']>;
  harvestDate?: Maybe<Scalars['DateTime']>;
  isInactive: Scalars['Boolean'];
  isPartialHarvest: Scalars['Boolean'];
  roomId?: Maybe<Scalars['Int']>;
  roomName?: Maybe<Scalars['String']>;
  tableId?: Maybe<Scalars['Int']>;
  unitOfMeasure: UnitOfMeasure;
  harvestStatus?: Maybe<MjfBatchStatusType>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type HarvestDtoConnection = {
  __typename?: 'HarvestDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<HarvestDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<HarvestDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type HarvestDtoEdge = {
  __typename?: 'HarvestDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: HarvestDto;
};

export type HarvestPlantDto = {
  __typename?: 'HarvestPlantDto';
  strainId?: Maybe<Scalars['Int']>;
  strainName?: Maybe<Scalars['String']>;
  flowerWeight: Scalars['Decimal'];
  wasteWeight: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type ImageDto = {
  __typename?: 'ImageDto';
  name?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ImageDtoConnection = {
  __typename?: 'ImageDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ImageDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ImageDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ImageDtoEdge = {
  __typename?: 'ImageDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ImageDto;
};

export type InventoryDto = {
  __typename?: 'InventoryDto';
  lastPrinted?: Maybe<Scalars['DateTime']>;
  status: InventoryStatus;
  isQASample: Scalars['Boolean'];
  remainingQuantity: Scalars['Decimal'];
  startQuantity?: Maybe<Scalars['Decimal']>;
  traceabilityQuantity?: Maybe<Scalars['Decimal']>;
  isPlantBatch: Scalars['Boolean'];
  strain?: Maybe<StrainDto>;
  room?: Maybe<RoomDto>;
  product?: Maybe<ProductDto>;
  lineItem?: Maybe<LineItemDto>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type InventoryDtoConnection = {
  __typename?: 'InventoryDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<InventoryDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventoryDtoEdge = {
  __typename?: 'InventoryDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryDto;
};

export type InventorySampleDto = {
  __typename?: 'InventorySampleDto';
  lastPrinted?: Maybe<Scalars['DateTime']>;
  strain?: Maybe<StrainDto>;
  room?: Maybe<RoomDto>;
  product?: Maybe<ProductDto>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type InventorySampleDtoConnection = {
  __typename?: 'InventorySampleDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<InventorySampleDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventorySampleDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventorySampleDtoEdge = {
  __typename?: 'InventorySampleDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventorySampleDto;
};

export type InventoryTypeDto = {
  __typename?: 'InventoryTypeDto';
  name?: Maybe<Scalars['String']>;
  quantityType: QuantityType;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type InventoryTypeDtoConnection = {
  __typename?: 'InventoryTypeDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<InventoryTypeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryTypeDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventoryTypeDtoEdge = {
  __typename?: 'InventoryTypeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryTypeDto;
};

export type JurisdictionDto = {
  __typename?: 'JurisdictionDto';
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  traceabilityUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  regionAbbreviation?: Maybe<Scalars['String']>;
  complianceProvider?: Maybe<ComplianceProviderDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<JurisdictionSettingsDto>;
};

/** A connection to a list of items. */
export type JurisdictionDtoConnection = {
  __typename?: 'JurisdictionDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<JurisdictionDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<JurisdictionDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JurisdictionDtoEdge = {
  __typename?: 'JurisdictionDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: JurisdictionDto;
};

export type JurisdictionSettingsDto = {
  __typename?: 'JurisdictionSettingsDto';
  id: Scalars['Int'];
};

export type LabelDto = {
  __typename?: 'LabelDto';
  name?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  useV2: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  type: LabelType;
  typeName?: Maybe<Scalars['String']>;
  inventoryType?: Maybe<InventoryTypeDto>;
  config?: Maybe<Scalars['String']>;
  lastUsed?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type LabelPageDto = {
  __typename?: 'LabelPageDto';
  name?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  rows: Scalars['Int'];
  cols: Scalars['Int'];
  pageType: PageSizes;
  marginTop: Scalars['Decimal'];
  marginBottom: Scalars['Decimal'];
  marginLeft: Scalars['Decimal'];
  marginRight: Scalars['Decimal'];
  horizontalAlign: Scalars['Int'];
  cellSpacing: Scalars['Decimal'];
  cellSpacingTop: Scalars['Decimal'];
  cellSpacingBottom: Scalars['Decimal'];
  cellSpacingLeft: Scalars['Decimal'];
  cellSpacingRight: Scalars['Decimal'];
  cellHeight: Scalars['Decimal'];
  cellWidth: Scalars['Decimal'];
  height: Scalars['Decimal'];
  width: Scalars['Decimal'];
  showBorder: Scalars['Boolean'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type LicenseTypeDto = {
  __typename?: 'LicenseTypeDto';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  licenseGroupType: LicenseGroup;
};

export type LineItemDto = {
  __typename?: 'LineItemDto';
  strain?: Maybe<StrainDto>;
  room?: Maybe<RoomDto>;
  product?: Maybe<ProductDto>;
  purchaseOrder?: Maybe<PurchaseOrderDto>;
  sourceInventory?: Maybe<InventoryDto>;
  inventory?: Maybe<InventoryDto>;
  lastPrinted?: Maybe<Scalars['DateTime']>;
  transferDate?: Maybe<Scalars['DateTime']>;
  isVoided: Scalars['Boolean'];
  qtyPacked?: Maybe<Scalars['Decimal']>;
  isSample: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  quantityReceived?: Maybe<Scalars['Decimal']>;
  requestedQuantity?: Maybe<Scalars['Decimal']>;
  complianceId?: Maybe<Scalars['String']>;
  toComplianceId?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type LineItemDtoConnection = {
  __typename?: 'LineItemDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<LineItemDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LineItemDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LineItemDtoEdge = {
  __typename?: 'LineItemDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LineItemDto;
};

export type LineItemFilters = {
  __typename?: 'LineItemFilters';
  strains?: Maybe<Array<Maybe<FilterOptionItem>>>;
  vendors?: Maybe<Array<Maybe<FilterOptionItem>>>;
  inventoryTypes?: Maybe<Array<Maybe<FilterOptionItem>>>;
  productCategories?: Maybe<Array<Maybe<FilterOptionItem>>>;
  products?: Maybe<Array<Maybe<FilterOptionItem>>>;
  productSizeMin?: Maybe<Scalars['Decimal']>;
  productSizeMax?: Maybe<Scalars['Decimal']>;
  priceMin?: Maybe<Scalars['Decimal']>;
  priceMax?: Maybe<Scalars['Decimal']>;
  totalSales: Scalars['Decimal'];
  avgSales: Scalars['Decimal'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new user */
  addUser?: Maybe<UserDto>;
  /** Creates a new stand alone account */
  createAccount?: Maybe<AccountDto>;
  /** updates an organization record */
  updateOrganization?: Maybe<OrganizationDto>;
  /** Creates an organization record */
  wholesale_CreateOrganization?: Maybe<OrganizationDto>;
  submitHarvests?: Maybe<HarvestDto>;
  generateLabelsPdf?: Maybe<FileModel>;
  createRoom?: Maybe<RoomDto>;
  updateRoom?: Maybe<RoomDto>;
  deleteRooms: Scalars['Boolean'];
  deleteRoom: Scalars['Boolean'];
  createRoomTable?: Maybe<RoomTableDto>;
  updateRoomTable?: Maybe<RoomTableDto>;
  deleteRoomTable: Scalars['Boolean'];
  createStrain?: Maybe<StrainDto>;
  updateStrain?: Maybe<StrainDto>;
  deleteStrains: Scalars['Boolean'];
  deleteStrain: Scalars['Boolean'];
  createEmployee?: Maybe<EmployeeDto>;
  inviteEmployee?: Maybe<EmployeeDto>;
  updateEmployee?: Maybe<EmployeeDto>;
  deleteEmployee: Scalars['Boolean'];
  createVehicle?: Maybe<VehicleDto>;
  updateVehicle?: Maybe<VehicleDto>;
  deleteVehicle: Scalars['Boolean'];
  processCcrsReportsAndUpload?: Maybe<Scalars['String']>;
  resubmitHangingUploads?: Maybe<Scalars['String']>;
  submitCcrsReports?: Maybe<Scalars['String']>;
  resubmitCcrsCsvFiles?: Maybe<Array<Scalars['Boolean']>>;
  reconcileErrors?: Maybe<Scalars['String']>;
  processCcrsManifest?: Maybe<CcrsManifestUploadDto>;
};


export type MutationAddUserArgs = {
  model?: Maybe<AddUserInput>;
};


export type MutationCreateAccountArgs = {
  model?: Maybe<CreateAccountInput>;
};


export type MutationUpdateOrganizationArgs = {
  model?: Maybe<UpdateOrganizationInput>;
};


export type MutationWholesale_CreateOrganizationArgs = {
  model?: Maybe<CreateWholesaleOrganizationInput>;
};


export type MutationSubmitHarvestsArgs = {
  harvestDtos?: Maybe<Array<Maybe<HarvestDtoInput>>>;
};


export type MutationGenerateLabelsPdfArgs = {
  labelPrintModel?: Maybe<LabelPdfPrintModelInput>;
};


export type MutationCreateRoomArgs = {
  room: RoomCreateDtoInput;
};


export type MutationUpdateRoomArgs = {
  room: RoomUpdateDtoInput;
};


export type MutationDeleteRoomsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteRoomArgs = {
  id: Scalars['Int'];
};


export type MutationCreateRoomTableArgs = {
  roomTable: RoomTableCreateDtoInput;
};


export type MutationUpdateRoomTableArgs = {
  roomTable: RoomTableDtoInput;
};


export type MutationDeleteRoomTableArgs = {
  id: Scalars['Int'];
};


export type MutationCreateStrainArgs = {
  strain: StrainCreateDtoInput;
};


export type MutationUpdateStrainArgs = {
  strain: StrainUpdateDtoInput;
};


export type MutationDeleteStrainsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteStrainArgs = {
  id: Scalars['Int'];
};


export type MutationCreateEmployeeArgs = {
  employee: EmployeeCreateDtoInput;
};


export type MutationInviteEmployeeArgs = {
  employee: EmployeeInviteDtoInput;
};


export type MutationUpdateEmployeeArgs = {
  employee: EmployeeUpdateDtoInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['Int'];
};


export type MutationCreateVehicleArgs = {
  vehicle: VehicleCreateDtoInput;
};


export type MutationUpdateVehicleArgs = {
  vehicle: VehicleUpdateDtoInput;
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['Int'];
};


export type MutationProcessCcrsReportsAndUploadArgs = {
  reportingCadence?: Maybe<CcrsReportingCadence>;
  applicationScope?: Maybe<CcrsApplicationScope>;
  organizationKey?: Maybe<Scalars['String']>;
};


export type MutationResubmitHangingUploadsArgs = {
  atLeastOld?: Maybe<Scalars['DateTime']>;
  waitTime?: Maybe<Scalars['Int']>;
};


export type MutationSubmitCcrsReportsArgs = {
  event?: Maybe<SubmitCcrsReportEventInput>;
};


export type MutationResubmitCcrsCsvFilesArgs = {
  ccrsUploadIds?: Maybe<Array<Scalars['Int']>>;
};


export type MutationReconcileErrorsArgs = {
  reconcileEvent?: Maybe<ReconcileErrorsEventInput>;
};


export type MutationProcessCcrsManifestArgs = {
  manifestJobInput?: Maybe<ManifestJobInput>;
};

export type OrganizationDto = {
  __typename?: 'OrganizationDto';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePlanKey?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  accountsCount: Scalars['Int'];
  isVerified: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isGrowFlow: Scalars['Boolean'];
  isDelinquent: Scalars['Boolean'];
  isLost: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPaidWithCheck: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  trialStartDate?: Maybe<Scalars['DateTime']>;
  trialEndDate?: Maybe<Scalars['DateTime']>;
  firstPaidDate?: Maybe<Scalars['DateTime']>;
  verifiedDate?: Maybe<Scalars['DateTime']>;
  lostDate?: Maybe<Scalars['DateTime']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  organizationStatus: OrganizationStatus;
};

/** A connection to a list of items. */
export type OrganizationDtoConnection = {
  __typename?: 'OrganizationDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<OrganizationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrganizationDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganizationDtoEdge = {
  __typename?: 'OrganizationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrganizationDto;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PerpetualInventoryDetail = {
  __typename?: 'PerpetualInventoryDetail';
  measure?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  netPurchases: Scalars['Decimal'];
  netConversions: Scalars['Decimal'];
  netSales: Scalars['Decimal'];
  netAdjustments: Scalars['Decimal'];
  netImported: Scalars['Decimal'];
  netWaste: Scalars['Decimal'];
  endingBalance: Scalars['Decimal'];
};

export type PerpetualInventorySummary = {
  __typename?: 'PerpetualInventorySummary';
  measure?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  netPurchases: Scalars['Decimal'];
  netConversions: Scalars['Decimal'];
  netSales: Scalars['Decimal'];
  netAdjustments: Scalars['Decimal'];
  netImported: Scalars['Decimal'];
  netWaste: Scalars['Decimal'];
  endingBalance: Scalars['Decimal'];
};

export type PlantDto = {
  __typename?: 'PlantDto';
  strain?: Maybe<StrainDto>;
  flowerWeight: Scalars['Decimal'];
  wasteWeight: Scalars['Decimal'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  stage: PlantStage;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type PlantDtoConnection = {
  __typename?: 'PlantDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PlantDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlantDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PlantDtoEdge = {
  __typename?: 'PlantDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlantDto;
};

export type PrintNodeComputer = {
  __typename?: 'PrintNodeComputer';
  id: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  inet?: Maybe<Scalars['String']>;
  inet6?: Maybe<Scalars['String']>;
  hostName?: Maybe<Scalars['String']>;
  jre?: Maybe<Scalars['String']>;
  createTimeStamp: Scalars['DateTime'];
  state?: Maybe<Scalars['String']>;
};

export type PrintNodePrinter = {
  __typename?: 'PrintNodePrinter';
  id: Scalars['Long'];
  computer?: Maybe<PrintNodeComputer>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  capabilities?: Maybe<PrintNodePrinterCapabilities>;
  default: Scalars['Boolean'];
  createTimeStamp: Scalars['DateTime'];
  state?: Maybe<Scalars['String']>;
};

export type PrintNodePrinterCapabilities = {
  __typename?: 'PrintNodePrinterCapabilities';
  bins?: Maybe<Array<Maybe<Scalars['String']>>>;
  collate: Scalars['Boolean'];
  copies: Scalars['Int'];
  color: Scalars['Boolean'];
  dpis?: Maybe<Array<Maybe<Scalars['String']>>>;
  medias?: Maybe<Array<Maybe<Scalars['String']>>>;
  nup?: Maybe<Array<Scalars['Int']>>;
  supportsCustomPaperSize: Scalars['Boolean'];
};

export type ProductAttributeDto = {
  __typename?: 'ProductAttributeDto';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attribute?: Maybe<ProductAttributeDto>;
  productAttributeId: Scalars['Int'];
  order: Scalars['Int'];
  attributeTerms?: Maybe<Array<Maybe<ProductAttributeTermDto>>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProductAttributeDtoConnection = {
  __typename?: 'ProductAttributeDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductAttributeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductAttributeDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductAttributeDtoEdge = {
  __typename?: 'ProductAttributeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductAttributeDto;
};

export type ProductAttributeTermDto = {
  __typename?: 'ProductAttributeTermDto';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attribute?: Maybe<ProductAttributeDto>;
  order: Scalars['Int'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProductAttributeTermDtoConnection = {
  __typename?: 'ProductAttributeTermDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductAttributeTermDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductAttributeTermDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductAttributeTermDtoEdge = {
  __typename?: 'ProductAttributeTermDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductAttributeTermDto;
};

export type ProductBrandDto = {
  __typename?: 'ProductBrandDto';
  name?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProductBrandDtoConnection = {
  __typename?: 'ProductBrandDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductBrandDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductBrandDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductBrandDtoEdge = {
  __typename?: 'ProductBrandDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductBrandDto;
};

export type ProductCategoryDto = {
  __typename?: 'ProductCategoryDto';
  name?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProductCategoryDtoConnection = {
  __typename?: 'ProductCategoryDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductCategoryDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductCategoryDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductCategoryDtoEdge = {
  __typename?: 'ProductCategoryDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductCategoryDto;
};

export type ProductDto = {
  __typename?: 'ProductDto';
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Decimal']>;
  labelId?: Maybe<Scalars['Int']>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  traceableName?: Maybe<Scalars['String']>;
  attributeTerms?: Maybe<Array<Maybe<ProductAttributeTermDto>>>;
  strain?: Maybe<StrainDto>;
  inventoryType?: Maybe<InventoryTypeDto>;
  brand?: Maybe<ProductBrandDto>;
  category?: Maybe<ProductCategoryDto>;
  label?: Maybe<LabelDto>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProductDtoConnection = {
  __typename?: 'ProductDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductDtoEdge = {
  __typename?: 'ProductDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductDto;
};

export type PurchaseOrderDto = {
  __typename?: 'PurchaseOrderDto';
  isVoided: Scalars['Boolean'];
  poNumber: Scalars['Int'];
  stage: PurchaseOrderStage;
  manifestComplianceId?: Maybe<Scalars['String']>;
  vendor?: Maybe<VendorDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type PurchaseOrderDtoConnection = {
  __typename?: 'PurchaseOrderDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PurchaseOrderDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PurchaseOrderDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PurchaseOrderDtoEdge = {
  __typename?: 'PurchaseOrderDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PurchaseOrderDto;
};

export type Query = {
  __typename?: 'Query';
  vendors?: Maybe<VendorDtoConnection>;
  vendorById?: Maybe<VendorDto>;
  myAccounts?: Maybe<AccountDtoConnection>;
  myAccount?: Maybe<AccountDto>;
  allUsers: Array<Maybe<AllUserDto>>;
  me?: Maybe<UserDto>;
  accountUser?: Maybe<AccountUserDto>;
  strains?: Maybe<StrainDtoConnection>;
  products?: Maybe<ProductDtoConnection>;
  productCategories?: Maybe<ProductCategoryDtoConnection>;
  productBrands?: Maybe<ProductBrandDtoConnection>;
  productAttributes?: Maybe<ProductAttributeDtoConnection>;
  productAttributeTerms?: Maybe<ProductAttributeTermDtoConnection>;
  inventoryTypes?: Maybe<InventoryTypeDtoConnection>;
  storeFronts?: Maybe<StoreFrontDtoConnection>;
  harvests?: Maybe<HarvestDtoConnection>;
  lineItemFilters?: Maybe<LineItemFilters>;
  typeOfReports?: Maybe<ReportConfigurationDtoConnection>;
  typeOfReportsByJurisdiction?: Maybe<ReportConfigurationDtoConnection>;
  lineItems?: Maybe<LineItemDtoConnection>;
  purchaseOrders?: Maybe<PurchaseOrderDtoConnection>;
  jurisdictions?: Maybe<JurisdictionDtoConnection>;
  myJurisdiction?: Maybe<JurisdictionDto>;
  labels: Array<Maybe<LabelDto>>;
  accountLabels: Array<Maybe<LabelDto>>;
  printers: Array<Maybe<PrintNodePrinter>>;
  labelPages: Array<Maybe<LabelPageDto>>;
  allOrganizations?: Maybe<AllOrganizationDtoConnection>;
  userOrganizations?: Maybe<OrganizationDtoConnection>;
  disposals?: Maybe<DisposalDtoConnection>;
  plants?: Maybe<PlantDtoConnection>;
  ccrsCsvUpload?: Maybe<CcrsCsvUploadDtoConnection>;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadDtoConnection>;
  inventories?: Maybe<InventoryDtoConnection>;
  inventorySamples?: Maybe<InventorySampleDtoConnection>;
  rooms?: Maybe<RoomDtoConnection>;
  traceabilityRoomType?: Maybe<TraceabilityRoomTypeDtoConnection>;
  roomtables?: Maybe<RoomTableDtoConnection>;
  employees?: Maybe<EmployeeDtoConnection>;
  vehicles?: Maybe<VehicleDtoConnection>;
  images?: Maybe<ImageDtoConnection>;
  perpetualInventorySummary?: Maybe<Array<Maybe<PerpetualInventorySummary>>>;
  perpetualInventoryDetails?: Maybe<Array<Maybe<PerpetualInventoryDetail>>>;
  serviceStatus?: Maybe<ServiceStatusDto>;
};


export type QueryVendorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<VendorSortInput>>;
  where?: Maybe<VendorFilterInput>;
};


export type QueryVendorByIdArgs = {
  id: Scalars['Int'];
};


export type QueryMyAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<AccountSortInput>>;
  where?: Maybe<AccountFilterInput>;
};


export type QueryAllUsersArgs = {
  organizationId: Scalars['String'];
};


export type QueryAccountUserArgs = {
  order?: Maybe<Array<AccountUserSortInput>>;
  where?: Maybe<AccountUserFilterInput>;
};


export type QueryStrainsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<StrainSortInput>>;
  where?: Maybe<StrainFilterInput>;
};


export type QueryProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<ProductSortInput>>;
  where?: Maybe<ProductFilterInput>;
};


export type QueryProductCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<CategorySortInput>>;
  where?: Maybe<CategoryFilterInput>;
};


export type QueryProductBrandsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<BrandSortInput>>;
  where?: Maybe<BrandFilterInput>;
};


export type QueryProductAttributesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<ProductAttributeSortInput>>;
  where?: Maybe<ProductAttributeFilterInput>;
};


export type QueryProductAttributeTermsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<ProductAttributeTermSortInput>>;
  where?: Maybe<ProductAttributeTermFilterInput>;
};


export type QueryInventoryTypesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<InventoryTypeSortInput>>;
  where?: Maybe<InventoryTypeFilterInput>;
};


export type QueryStoreFrontsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<StoreFrontSortInput>>;
  where?: Maybe<StoreFrontFilterInput>;
};


export type QueryHarvestsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<BatchSortInput>>;
  where?: Maybe<BatchFilterInput>;
};


export type QueryLineItemFiltersArgs = {
  where?: Maybe<LineItemFilterInput>;
};


export type QueryTypeOfReportsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<TypeOfReportSortInput>>;
  where?: Maybe<TypeOfReportFilterInput>;
};


export type QueryTypeOfReportsByJurisdictionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<TypeOfReportSortInput>>;
  where?: Maybe<TypeOfReportFilterInput>;
};


export type QueryLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<LineItemSortInput>>;
  where?: Maybe<LineItemFilterInput>;
};


export type QueryPurchaseOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<PurchaseOrderSortInput>>;
  where?: Maybe<PurchaseOrderFilterInput>;
};


export type QueryJurisdictionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<TypeOfJurisdictionSortInput>>;
  where?: Maybe<TypeOfJurisdictionFilterInput>;
};


export type QueryLabelsArgs = {
  order?: Maybe<Array<LabelSortInput>>;
  where?: Maybe<LabelFilterInput>;
};


export type QueryAccountLabelsArgs = {
  order?: Maybe<Array<LabelSortInput>>;
  where?: Maybe<LabelFilterInput>;
};


export type QueryPrintersArgs = {
  where?: Maybe<PrintNodePrinterFilterInput>;
};


export type QueryLabelPagesArgs = {
  includeDefault?: Maybe<Scalars['Boolean']>;
  where?: Maybe<LabelPageFilterInput>;
};


export type QueryAllOrganizationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<AllOrganizationSortInput>>;
  where?: Maybe<AllOrganizationFilterInput>;
};


export type QueryUserOrganizationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<OrganizationSortInput>>;
  where?: Maybe<OrganizationFilterInput>;
};


export type QueryDisposalsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<DisposalSortInput>>;
  where?: Maybe<DisposalFilterInput>;
};


export type QueryPlantsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<PlantSortInput>>;
  where?: Maybe<PlantFilterInput>;
};


export type QueryCcrsCsvUploadArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<CcrsCsvUploadSortInput>>;
  where?: Maybe<CcrsCsvUploadFilterInput>;
};


export type QueryCcrsManifestUploadArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<CcrsManifestUploadSortInput>>;
  where?: Maybe<CcrsManifestUploadFilterInput>;
};


export type QueryInventoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<InventorySortInput>>;
  where?: Maybe<InventoryFilterInput>;
};


export type QueryInventorySamplesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<InventorySampleSortInput>>;
  where?: Maybe<InventorySampleFilterInput>;
};


export type QueryRoomsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<RoomSortInput>>;
  where?: Maybe<RoomFilterInput>;
};


export type QueryTraceabilityRoomTypeArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<TraceabilityRoomTypeSortInput>>;
  where?: Maybe<TraceabilityRoomTypeFilterInput>;
};


export type QueryRoomtablesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<RoomTableSortInput>>;
  where?: Maybe<RoomTableFilterInput>;
};


export type QueryEmployeesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<EmployeeSortInput>>;
  where?: Maybe<EmployeeFilterInput>;
};


export type QueryVehiclesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<VehicleSortInput>>;
  where?: Maybe<VehicleFilterInput>;
};


export type QueryImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Array<ImageSortInput>>;
  where?: Maybe<ImageFilterInput>;
};


export type QueryPerpetualInventorySummaryArgs = {
  startDate: Scalars['DateTime'];
};


export type QueryPerpetualInventoryDetailsArgs = {
  startDate: Scalars['DateTime'];
  categoryFilter?: Maybe<Scalars['String']>;
  measureFilter?: Maybe<Scalars['String']>;
};

export type ReportConfigurationDto = {
  __typename?: 'ReportConfigurationDto';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ReportConfigurationDtoConnection = {
  __typename?: 'ReportConfigurationDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ReportConfigurationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ReportConfigurationDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReportConfigurationDtoEdge = {
  __typename?: 'ReportConfigurationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReportConfigurationDto;
};

export type RoomDto = {
  __typename?: 'RoomDto';
  isForPlants: Scalars['Boolean'];
  roomTables?: Maybe<Array<Maybe<RoomTableDto>>>;
  isForHarvests: Scalars['Boolean'];
  complianceId?: Maybe<Scalars['String']>;
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  purpose?: Maybe<SiteType>;
  traceabilityRoomTypeId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type RoomDtoConnection = {
  __typename?: 'RoomDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RoomDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RoomDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RoomDtoEdge = {
  __typename?: 'RoomDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RoomDto;
};

export type RoomTableDto = {
  __typename?: 'RoomTableDto';
  id: Scalars['Int'];
  rows?: Maybe<Scalars['Int']>;
  cols?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoomTableType>;
  notes?: Maybe<Scalars['String']>;
  roomId: Scalars['Int'];
};

/** A connection to a list of items. */
export type RoomTableDtoConnection = {
  __typename?: 'RoomTableDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RoomTableDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RoomTableDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RoomTableDtoEdge = {
  __typename?: 'RoomTableDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RoomTableDto;
};

export type ServiceStatusDto = {
  __typename?: 'ServiceStatusDto';
  asOf: Scalars['DateTime'];
  assemblies?: Maybe<Array<Maybe<DotNetAssemblyDto>>>;
};

export type StoreFrontDto = {
  __typename?: 'StoreFrontDto';
  name?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type StoreFrontDtoConnection = {
  __typename?: 'StoreFrontDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StoreFrontDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StoreFrontDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StoreFrontDtoEdge = {
  __typename?: 'StoreFrontDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StoreFrontDto;
};

export type StrainDto = {
  __typename?: 'StrainDto';
  complianceId?: Maybe<Scalars['String']>;
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  strainType?: Maybe<StrainType>;
  sativaPercentage?: Maybe<Scalars['Decimal']>;
  indicaPercentage?: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type StrainDtoConnection = {
  __typename?: 'StrainDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StrainDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StrainDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StrainDtoEdge = {
  __typename?: 'StrainDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StrainDto;
};

export type TraceabilityRoomTypeDto = {
  __typename?: 'TraceabilityRoomTypeDto';
  isForPlantBatches: Scalars['Boolean'];
  isForPlants: Scalars['Boolean'];
  isForHarvests: Scalars['Boolean'];
  isForPackages: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type TraceabilityRoomTypeDtoConnection = {
  __typename?: 'TraceabilityRoomTypeDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<TraceabilityRoomTypeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TraceabilityRoomTypeDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TraceabilityRoomTypeDtoEdge = {
  __typename?: 'TraceabilityRoomTypeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TraceabilityRoomTypeDto;
};

export type UserDto = {
  __typename?: 'UserDto';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isGrowFlow: Scalars['Boolean'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
};

export type UserRoleDto = {
  __typename?: 'UserRoleDto';
  licenseNumber?: Maybe<Scalars['String']>;
  roleNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VehicleDto = {
  __typename?: 'VehicleDto';
  complianceId?: Maybe<Scalars['String']>;
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  plate?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type VehicleDtoConnection = {
  __typename?: 'VehicleDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VehicleDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VehicleDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VehicleDtoEdge = {
  __typename?: 'VehicleDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VehicleDto;
};

export type VendorDto = {
  __typename?: 'VendorDto';
  jurisdiction?: Maybe<JurisdictionDto>;
  name?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  ubi?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  isFavorite: Scalars['Boolean'];
  nickName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  pricingId?: Maybe<Scalars['Int']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ubi16?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<Scalars['DateTime']>;
  qBCustomerId?: Maybe<Scalars['String']>;
  isTransportAllowed?: Maybe<Scalars['Boolean']>;
  licenseType?: Maybe<LicenseTypeDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** A connection to a list of items. */
export type VendorDtoConnection = {
  __typename?: 'VendorDtoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VendorDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VendorDto>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorDtoEdge = {
  __typename?: 'VendorDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VendorDto;
};

export type AccountFilterInput = {
  and?: Maybe<Array<AccountFilterInput>>;
  or?: Maybe<Array<AccountFilterInput>>;
  isStandAlone?: Maybe<BooleanOperationFilterInput>;
  organization?: Maybe<AllOrganizationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  complianceCredential?: Maybe<ComplianceCredentialFilterInput>;
  isVerified?: Maybe<BooleanOperationFilterInput>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  vendorId?: Maybe<ComparableInt32OperationFilterInput>;
  vendor?: Maybe<VendorFilterInput>;
  isGrowFlow?: Maybe<BooleanOperationFilterInput>;
  isDisabled?: Maybe<BooleanOperationFilterInput>;
  line1?: Maybe<StringOperationFilterInput>;
  line2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  region?: Maybe<StringOperationFilterInput>;
  postalCode?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  isPaid?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type AccountRolesFilterInput = {
  and?: Maybe<Array<AccountRolesFilterInput>>;
  or?: Maybe<Array<AccountRolesFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  groupName?: Maybe<StringOperationFilterInput>;
  isPrimary?: Maybe<BooleanOperationFilterInput>;
  accounts?: Maybe<ListFilterInputTypeOfAccountUserFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type AccountSortInput = {
  isStandAlone?: Maybe<SortEnumType>;
  organization?: Maybe<AllOrganizationSortInput>;
  name?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  complianceCredential?: Maybe<ComplianceCredentialSortInput>;
  isVerified?: Maybe<SortEnumType>;
  isInactive?: Maybe<SortEnumType>;
  vendorId?: Maybe<SortEnumType>;
  vendor?: Maybe<VendorSortInput>;
  isGrowFlow?: Maybe<SortEnumType>;
  isDisabled?: Maybe<SortEnumType>;
  line1?: Maybe<SortEnumType>;
  line2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  region?: Maybe<SortEnumType>;
  postalCode?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  isPaid?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type AccountUserFilterInput = {
  and?: Maybe<Array<AccountUserFilterInput>>;
  or?: Maybe<Array<AccountUserFilterInput>>;
  account?: Maybe<AccountFilterInput>;
  user?: Maybe<UserFilterInput>;
  roles?: Maybe<ListFilterInputTypeOfAccountRolesFilterInput>;
  homeModule?: Maybe<StringOperationFilterInput>;
  lastLogin?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDisabled?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type AccountUserSortInput = {
  account?: Maybe<AccountSortInput>;
  user?: Maybe<UserSortInput>;
  homeModule?: Maybe<SortEnumType>;
  lastLogin?: Maybe<SortEnumType>;
  isDisabled?: Maybe<SortEnumType>;
};

export type AddUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type AllOrganizationFilterInput = {
  and?: Maybe<Array<AllOrganizationFilterInput>>;
  or?: Maybe<Array<AllOrganizationFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  organizationKey?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  stripeCustomerId?: Maybe<StringOperationFilterInput>;
  stripeSubscriptionId?: Maybe<StringOperationFilterInput>;
  stripePlanKey?: Maybe<StringOperationFilterInput>;
  primaryPhone?: Maybe<StringOperationFilterInput>;
  primaryEmail?: Maybe<StringOperationFilterInput>;
  accounts?: Maybe<ListFilterInputTypeOfAccountFilterInput>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  organizationStatus?: Maybe<OrganizationStatusOperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type AllOrganizationSortInput = {
  id?: Maybe<SortEnumType>;
  organizationKey?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  stripeCustomerId?: Maybe<SortEnumType>;
  stripeSubscriptionId?: Maybe<SortEnumType>;
  stripePlanKey?: Maybe<SortEnumType>;
  primaryPhone?: Maybe<SortEnumType>;
  primaryEmail?: Maybe<SortEnumType>;
  isInactive?: Maybe<SortEnumType>;
  organizationStatus?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type BatchFilterInput = {
  and?: Maybe<Array<BatchFilterInput>>;
  or?: Maybe<Array<BatchFilterInput>>;
  roomId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  isPartialHarvest?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BatchSortInput = {
  roomId?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  isInactive?: Maybe<SortEnumType>;
  isPartialHarvest?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BrandFilterInput = {
  and?: Maybe<Array<BrandFilterInput>>;
  or?: Maybe<Array<BrandFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BrandSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type CategoryFilterInput = {
  and?: Maybe<Array<CategoryFilterInput>>;
  or?: Maybe<Array<CategoryFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type CategorySortInput = {
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type CcrsApplicationScopeOperationFilterInput = {
  eq?: Maybe<CcrsApplicationScope>;
  neq?: Maybe<CcrsApplicationScope>;
  in?: Maybe<Array<CcrsApplicationScope>>;
  nin?: Maybe<Array<CcrsApplicationScope>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type CcrsCsvUploadFilterInput = {
  and?: Maybe<Array<CcrsCsvUploadFilterInput>>;
  or?: Maybe<Array<CcrsCsvUploadFilterInput>>;
  reportGeneratedAt?: Maybe<ComparableDateTimeOperationFilterInput>;
  errorType?: Maybe<NullableOfCcrsCsvUploadErrorTypeOperationFilterInput>;
  csvFileName?: Maybe<StringOperationFilterInput>;
  entityName?: Maybe<StringOperationFilterInput>;
  csvErrorLink?: Maybe<StringOperationFilterInput>;
  numberOfRecords?: Maybe<ComparableInt32OperationFilterInput>;
  numberOfErrorRecords?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parentCcrsCsvUploadId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parentCcrsCsvUpload?: Maybe<CcrsCsvUploadFilterInput>;
  childCcrsCsvUploads?: Maybe<ListFilterInputTypeOfCcrsCsvUploadFilterInput>;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadFilterInput>;
  ccrsManifestUploadId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  licenseNumber?: Maybe<StringOperationFilterInput>;
  isSandBox?: Maybe<BooleanOperationFilterInput>;
  applicationScope?: Maybe<CcrsApplicationScopeOperationFilterInput>;
  status?: Maybe<SyncStatusOperationFilterInput>;
  statusName?: Maybe<StringOperationFilterInput>;
  transactionId?: Maybe<StringOperationFilterInput>;
  processingDuration?: Maybe<ComparableInt64OperationFilterInput>;
  log?: Maybe<StringOperationFilterInput>;
  logLink?: Maybe<StringOperationFilterInput>;
  logDirectoryLink?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type CcrsCsvUploadSortInput = {
  reportGeneratedAt?: Maybe<SortEnumType>;
  errorType?: Maybe<SortEnumType>;
  csvFileName?: Maybe<SortEnumType>;
  entityName?: Maybe<SortEnumType>;
  csvErrorLink?: Maybe<SortEnumType>;
  numberOfRecords?: Maybe<SortEnumType>;
  numberOfErrorRecords?: Maybe<SortEnumType>;
  parentCcrsCsvUploadId?: Maybe<SortEnumType>;
  parentCcrsCsvUpload?: Maybe<CcrsCsvUploadSortInput>;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadSortInput>;
  ccrsManifestUploadId?: Maybe<SortEnumType>;
  licenseNumber?: Maybe<SortEnumType>;
  isSandBox?: Maybe<SortEnumType>;
  applicationScope?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusName?: Maybe<SortEnumType>;
  transactionId?: Maybe<SortEnumType>;
  processingDuration?: Maybe<SortEnumType>;
  log?: Maybe<SortEnumType>;
  logLink?: Maybe<SortEnumType>;
  logDirectoryLink?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type CcrsManifestUploadFilterInput = {
  and?: Maybe<Array<CcrsManifestUploadFilterInput>>;
  or?: Maybe<Array<CcrsManifestUploadFilterInput>>;
  manifestLink?: Maybe<StringOperationFilterInput>;
  manifestId?: Maybe<StringOperationFilterInput>;
  externalId?: Maybe<StringOperationFilterInput>;
  purchaseOrderNumber?: Maybe<StringOperationFilterInput>;
  payLoad?: Maybe<StringOperationFilterInput>;
  licenseNumber?: Maybe<StringOperationFilterInput>;
  isSandBox?: Maybe<BooleanOperationFilterInput>;
  applicationScope?: Maybe<CcrsApplicationScopeOperationFilterInput>;
  status?: Maybe<SyncStatusOperationFilterInput>;
  statusName?: Maybe<StringOperationFilterInput>;
  transactionId?: Maybe<StringOperationFilterInput>;
  processingDuration?: Maybe<ComparableInt64OperationFilterInput>;
  log?: Maybe<StringOperationFilterInput>;
  logLink?: Maybe<StringOperationFilterInput>;
  logDirectoryLink?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type CcrsManifestUploadSortInput = {
  manifestLink?: Maybe<SortEnumType>;
  manifestId?: Maybe<SortEnumType>;
  externalId?: Maybe<SortEnumType>;
  purchaseOrderNumber?: Maybe<SortEnumType>;
  payLoad?: Maybe<SortEnumType>;
  licenseNumber?: Maybe<SortEnumType>;
  isSandBox?: Maybe<SortEnumType>;
  applicationScope?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusName?: Maybe<SortEnumType>;
  transactionId?: Maybe<SortEnumType>;
  processingDuration?: Maybe<SortEnumType>;
  log?: Maybe<SortEnumType>;
  logLink?: Maybe<SortEnumType>;
  logDirectoryLink?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  nin?: Maybe<Array<Scalars['Decimal']>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Scalars['Uuid']>>;
  nin?: Maybe<Array<Scalars['Uuid']>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableInt64OperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Scalars['Long']>>;
  nin?: Maybe<Array<Scalars['Long']>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComplianceCredentialFilterInput = {
  and?: Maybe<Array<ComplianceCredentialFilterInput>>;
  or?: Maybe<Array<ComplianceCredentialFilterInput>>;
  jurisdiction?: Maybe<JurisdictionOperationFilterInput>;
  username?: Maybe<StringOperationFilterInput>;
  password?: Maybe<StringOperationFilterInput>;
  gFUsername?: Maybe<StringOperationFilterInput>;
  gFPassword?: Maybe<StringOperationFilterInput>;
  metrcUIUsername?: Maybe<StringOperationFilterInput>;
  metrcUIPassword?: Maybe<StringOperationFilterInput>;
  metrcUIDisabled?: Maybe<BooleanOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  locationId?: Maybe<StringOperationFilterInput>;
  isSandbox?: Maybe<BooleanOperationFilterInput>;
  isMetrcMock?: Maybe<BooleanOperationFilterInput>;
  oldBtUsername?: Maybe<StringOperationFilterInput>;
  oldBtPassword?: Maybe<StringOperationFilterInput>;
  ccaPassword?: Maybe<StringOperationFilterInput>;
  ccaStartTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  ccaReportingEnabled?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComplianceCredentialSortInput = {
  jurisdiction?: Maybe<SortEnumType>;
  username?: Maybe<SortEnumType>;
  password?: Maybe<SortEnumType>;
  gFUsername?: Maybe<SortEnumType>;
  gFPassword?: Maybe<SortEnumType>;
  metrcUIUsername?: Maybe<SortEnumType>;
  metrcUIPassword?: Maybe<SortEnumType>;
  metrcUIDisabled?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  isSandbox?: Maybe<SortEnumType>;
  isMetrcMock?: Maybe<SortEnumType>;
  oldBtUsername?: Maybe<SortEnumType>;
  oldBtPassword?: Maybe<SortEnumType>;
  ccaPassword?: Maybe<SortEnumType>;
  ccaStartTime?: Maybe<SortEnumType>;
  ccaReportingEnabled?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type ComplianceProviderFilterInput = {
  and?: Maybe<Array<ComplianceProviderFilterInput>>;
  or?: Maybe<Array<ComplianceProviderFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  shortName?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ComplianceProviderSortInput = {
  id?: Maybe<SortEnumType>;
  shortName?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
};

export type CreateAccountInput = {
  licenseNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  createSandbox: Scalars['Boolean'];
  organizationId?: Maybe<Scalars['Uuid']>;
  organizationStripeSubscriptionId?: Maybe<Scalars['String']>;
  organizationStripeCustomerId?: Maybe<Scalars['String']>;
  jurisdictionCode?: Maybe<Scalars['String']>;
};

export type CreateWholesaleOrganizationInput = {
  name?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
};

export type DisposalFilterInput = {
  and?: Maybe<Array<DisposalFilterInput>>;
  or?: Maybe<Array<DisposalFilterInput>>;
  room?: Maybe<RoomFilterInput>;
  quantity?: Maybe<ComparableDecimalOperationFilterInput>;
  typeOfPlantWasteMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  batchId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  explanation?: Maybe<StringOperationFilterInput>;
  holdStartsAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  holdEndsAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDisposed?: Maybe<BooleanOperationFilterInput>;
  disposalAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  gFBatchId?: Maybe<ComparableInt32OperationFilterInput>;
  source?: Maybe<NullableOfMjfDisposalSourceTypeOperationFilterInput>;
  unit?: Maybe<NullableOfUnitOfMeasureOperationFilterInput>;
  isWet?: Maybe<BooleanOperationFilterInput>;
  plantId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  typeOfPlantDestroyReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  conversionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  wasteInventoryId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  inventoryId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  typeOfInventoryDestroyReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  strain?: Maybe<StrainFilterInput>;
  reason?: Maybe<NullableOfMjfDisposalReasonTypeOperationFilterInput>;
  method?: Maybe<StringOperationFilterInput>;
  disposalCert?: Maybe<StringOperationFilterInput>;
  wasteInventoryComplianceId?: Maybe<StringOperationFilterInput>;
  lastPrinted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type DisposalSortInput = {
  room?: Maybe<RoomSortInput>;
  quantity?: Maybe<SortEnumType>;
  typeOfPlantWasteMethodId?: Maybe<SortEnumType>;
  batchId?: Maybe<SortEnumType>;
  explanation?: Maybe<SortEnumType>;
  holdStartsAt?: Maybe<SortEnumType>;
  holdEndsAt?: Maybe<SortEnumType>;
  isDisposed?: Maybe<SortEnumType>;
  disposalAt?: Maybe<SortEnumType>;
  gFBatchId?: Maybe<SortEnumType>;
  source?: Maybe<SortEnumType>;
  unit?: Maybe<SortEnumType>;
  isWet?: Maybe<SortEnumType>;
  plantId?: Maybe<SortEnumType>;
  typeOfPlantDestroyReasonId?: Maybe<SortEnumType>;
  conversionId?: Maybe<SortEnumType>;
  wasteInventoryId?: Maybe<SortEnumType>;
  inventoryId?: Maybe<SortEnumType>;
  typeOfInventoryDestroyReasonId?: Maybe<SortEnumType>;
  strain?: Maybe<StrainSortInput>;
  reason?: Maybe<SortEnumType>;
  method?: Maybe<SortEnumType>;
  disposalCert?: Maybe<SortEnumType>;
  wasteInventoryComplianceId?: Maybe<SortEnumType>;
  lastPrinted?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type EmployeeCreateDtoInput = {
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
  vehicleId?: Maybe<Scalars['Int']>;
  driversLicense?: Maybe<Scalars['String']>;
  dateOfHire?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type EmployeeFilterInput = {
  and?: Maybe<Array<EmployeeFilterInput>>;
  or?: Maybe<Array<EmployeeFilterInput>>;
  complianceId?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  dateOfBirth?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dateOfHire?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  driversLicense?: Maybe<StringOperationFilterInput>;
  vehicleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  vendorId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  userId?: Maybe<StringOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type EmployeeInviteDtoInput = {
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type EmployeeSortInput = {
  complianceId?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  dateOfBirth?: Maybe<SortEnumType>;
  dateOfHire?: Maybe<SortEnumType>;
  driversLicense?: Maybe<SortEnumType>;
  vehicleId?: Maybe<SortEnumType>;
  vendorId?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  phoneNumber?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type EmployeeUpdateDtoInput = {
  id: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
  vehicleId?: Maybe<Scalars['Int']>;
  driversLicense?: Maybe<Scalars['String']>;
  dateOfHire?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type HarvestDtoInput = {
  id?: Maybe<Scalars['Int']>;
  harvestPlants?: Maybe<Array<Maybe<HarvestPlantDtoInput>>>;
  name?: Maybe<Scalars['String']>;
  harvestDate?: Maybe<Scalars['DateTime']>;
  isInactive: Scalars['Boolean'];
  isPartialHarvest: Scalars['Boolean'];
  roomId?: Maybe<Scalars['Int']>;
  roomName?: Maybe<Scalars['String']>;
  tableId?: Maybe<Scalars['Int']>;
  unitOfMeasure: UnitOfMeasure;
  harvestStatus?: Maybe<MjfBatchStatusType>;
  failure?: Maybe<HarvestDtoInput>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
};

export type HarvestPlantDtoInput = {
  strainId?: Maybe<Scalars['Int']>;
  strainName?: Maybe<Scalars['String']>;
  flowerWeight: Scalars['Decimal'];
  wasteWeight: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  deleteBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type ImageFilterInput = {
  and?: Maybe<Array<ImageFilterInput>>;
  or?: Maybe<Array<ImageFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ImageSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type InventoryFilterInput = {
  and?: Maybe<Array<InventoryFilterInput>>;
  or?: Maybe<Array<InventoryFilterInput>>;
  room?: Maybe<RoomFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  status?: Maybe<InventoryStatusOperationFilterInput>;
  isPlantBatch?: Maybe<BooleanOperationFilterInput>;
  startQuantity?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  product?: Maybe<ProductFilterInput>;
  remainingQuantity?: Maybe<ComparableDecimalOperationFilterInput>;
  traceabilityQuantity?: Maybe<ComparableDecimalOperationFilterInput>;
  strain?: Maybe<StrainFilterInput>;
  isQASample?: Maybe<BooleanOperationFilterInput>;
  unit?: Maybe<UnitOfMeasureOperationFilterInput>;
  lineItem?: Maybe<LineItemFilterInput>;
  lastPrinted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type InventorySampleFilterInput = {
  and?: Maybe<Array<InventorySampleFilterInput>>;
  or?: Maybe<Array<InventorySampleFilterInput>>;
  lastPrinted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type InventorySampleSortInput = {
  lastPrinted?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type InventorySortInput = {
  room?: Maybe<RoomSortInput>;
  complianceId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  isPlantBatch?: Maybe<SortEnumType>;
  startQuantity?: Maybe<SortEnumType>;
  product?: Maybe<ProductSortInput>;
  remainingQuantity?: Maybe<SortEnumType>;
  traceabilityQuantity?: Maybe<SortEnumType>;
  strain?: Maybe<StrainSortInput>;
  isQASample?: Maybe<SortEnumType>;
  unit?: Maybe<SortEnumType>;
  lineItem?: Maybe<LineItemSortInput>;
  lastPrinted?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type InventoryStatusOperationFilterInput = {
  eq?: Maybe<InventoryStatus>;
  neq?: Maybe<InventoryStatus>;
  in?: Maybe<Array<InventoryStatus>>;
  nin?: Maybe<Array<InventoryStatus>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type InventoryTypeFilterInput = {
  and?: Maybe<Array<InventoryTypeFilterInput>>;
  or?: Maybe<Array<InventoryTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  quantityType?: Maybe<QuantityTypeOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type InventoryTypeSortInput = {
  name?: Maybe<SortEnumType>;
  quantityType?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type JurisdictionOperationFilterInput = {
  eq?: Maybe<Jurisdiction>;
  neq?: Maybe<Jurisdiction>;
  in?: Maybe<Array<Jurisdiction>>;
  nin?: Maybe<Array<Jurisdiction>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type LabelFilterInput = {
  and?: Maybe<Array<LabelFilterInput>>;
  or?: Maybe<Array<LabelFilterInput>>;
  inventoryType?: Maybe<InventoryTypeFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  isTemplate?: Maybe<BooleanOperationFilterInput>;
  useV2?: Maybe<BooleanOperationFilterInput>;
  isSample?: Maybe<BooleanOperationFilterInput>;
  isPrimary?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  type?: Maybe<LabelTypeOperationFilterInput>;
  config?: Maybe<StringOperationFilterInput>;
  lastUsed?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  typeName?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type LabelPageFilterInput = {
  and?: Maybe<Array<LabelPageFilterInput>>;
  or?: Maybe<Array<LabelPageFilterInput>>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  rows?: Maybe<ComparableInt32OperationFilterInput>;
  cols?: Maybe<ComparableInt32OperationFilterInput>;
  pageType?: Maybe<PageSizesOperationFilterInput>;
  marginTop?: Maybe<ComparableDecimalOperationFilterInput>;
  marginBottom?: Maybe<ComparableDecimalOperationFilterInput>;
  marginLeft?: Maybe<ComparableDecimalOperationFilterInput>;
  marginRight?: Maybe<ComparableDecimalOperationFilterInput>;
  horizontalAlign?: Maybe<ComparableInt32OperationFilterInput>;
  cellSpacing?: Maybe<ComparableDecimalOperationFilterInput>;
  cellSpacingTop?: Maybe<ComparableDecimalOperationFilterInput>;
  cellSpacingBottom?: Maybe<ComparableDecimalOperationFilterInput>;
  cellSpacingLeft?: Maybe<ComparableDecimalOperationFilterInput>;
  cellSpacingRight?: Maybe<ComparableDecimalOperationFilterInput>;
  cellHeight?: Maybe<ComparableDecimalOperationFilterInput>;
  cellWidth?: Maybe<ComparableDecimalOperationFilterInput>;
  height?: Maybe<ComparableDecimalOperationFilterInput>;
  width?: Maybe<ComparableDecimalOperationFilterInput>;
  showBorder?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type LabelPdfPrintItemModelInput = {
  itemId: Scalars['Int'];
  labelId: Scalars['Int'];
  qty: Scalars['Int'];
};

export type LabelPdfPrintModelInput = {
  type: LabelType;
  labelPageId: Scalars['Int'];
  preview: Scalars['Boolean'];
  printHeaderId?: Maybe<Scalars['Int']>;
  printHeaderItemId?: Maybe<Scalars['Int']>;
  printerId?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<LabelPdfPrintItemModelInput>>>;
};

export type LabelSortInput = {
  inventoryType?: Maybe<InventoryTypeSortInput>;
  isDefault?: Maybe<SortEnumType>;
  isTemplate?: Maybe<SortEnumType>;
  useV2?: Maybe<SortEnumType>;
  isSample?: Maybe<SortEnumType>;
  isPrimary?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  config?: Maybe<SortEnumType>;
  lastUsed?: Maybe<SortEnumType>;
  typeName?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type LabelTypeOperationFilterInput = {
  eq?: Maybe<LabelType>;
  neq?: Maybe<LabelType>;
  in?: Maybe<Array<LabelType>>;
  nin?: Maybe<Array<LabelType>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type LineItemFilterInput = {
  and?: Maybe<Array<LineItemFilterInput>>;
  or?: Maybe<Array<LineItemFilterInput>>;
  total?: Maybe<ComparableDecimalOperationFilterInput>;
  price?: Maybe<ComparableDecimalOperationFilterInput>;
  inventory?: Maybe<InventoryFilterInput>;
  transferDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  requestedQuantity?: Maybe<ComparableDecimalOperationFilterInput>;
  qtyPacked?: Maybe<ComparableDecimalOperationFilterInput>;
  product?: Maybe<ProductFilterInput>;
  purchaseOrder?: Maybe<PurchaseOrderFilterInput>;
  strain?: Maybe<StrainFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  toComplianceId?: Maybe<StringOperationFilterInput>;
  isVoided?: Maybe<BooleanOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  quantityReceived?: Maybe<ComparableDecimalOperationFilterInput>;
  isSample?: Maybe<BooleanOperationFilterInput>;
  lastPrinted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type LineItemSortInput = {
  total?: Maybe<SortEnumType>;
  price?: Maybe<SortEnumType>;
  inventory?: Maybe<InventorySortInput>;
  transferDate?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
  requestedQuantity?: Maybe<SortEnumType>;
  qtyPacked?: Maybe<SortEnumType>;
  product?: Maybe<ProductSortInput>;
  purchaseOrder?: Maybe<PurchaseOrderSortInput>;
  strain?: Maybe<StrainSortInput>;
  complianceId?: Maybe<SortEnumType>;
  toComplianceId?: Maybe<SortEnumType>;
  isVoided?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  quantityReceived?: Maybe<SortEnumType>;
  isSample?: Maybe<SortEnumType>;
  lastPrinted?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
};

export type ListComparableInt32OperationFilterInput = {
  all?: Maybe<ComparableInt32OperationFilterInput>;
  none?: Maybe<ComparableInt32OperationFilterInput>;
  some?: Maybe<ComparableInt32OperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: Maybe<AccountFilterInput>;
  none?: Maybe<AccountFilterInput>;
  some?: Maybe<AccountFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountRolesFilterInput = {
  all?: Maybe<AccountRolesFilterInput>;
  none?: Maybe<AccountRolesFilterInput>;
  some?: Maybe<AccountRolesFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountUserFilterInput = {
  all?: Maybe<AccountUserFilterInput>;
  none?: Maybe<AccountUserFilterInput>;
  some?: Maybe<AccountUserFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCcrsCsvUploadFilterInput = {
  all?: Maybe<CcrsCsvUploadFilterInput>;
  none?: Maybe<CcrsCsvUploadFilterInput>;
  some?: Maybe<CcrsCsvUploadFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRoomTableFilterInput = {
  all?: Maybe<RoomTableFilterInput>;
  none?: Maybe<RoomTableFilterInput>;
  some?: Maybe<RoomTableFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfStoreFrontPermissionFilterInput = {
  all?: Maybe<StoreFrontPermissionFilterInput>;
  none?: Maybe<StoreFrontPermissionFilterInput>;
  some?: Maybe<StoreFrontPermissionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfStoreFrontUserVendorFilterInput = {
  all?: Maybe<StoreFrontUserVendorFilterInput>;
  none?: Maybe<StoreFrontUserVendorFilterInput>;
  some?: Maybe<StoreFrontUserVendorFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ListStringOperationFilterInput = {
  all?: Maybe<StringOperationFilterInput>;
  none?: Maybe<StringOperationFilterInput>;
  some?: Maybe<StringOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ManifestJobInput = {
  id: Scalars['Int'];
  externalId: Scalars['String'];
  licenseNumber: Scalars['String'];
  transactionId: Scalars['String'];
  deliveryType?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  transportationLicenseNumber?: Maybe<Scalars['String']>;
  departureTime: Scalars['DateTime'];
  arrivalTime: Scalars['DateTime'];
  seller: ManifestJobInputVendorInput;
  receiver: ManifestJobInputVendorInput;
  vehicle: ManifestJobInputVehicleInput;
  lineItems: Array<ManifestLineItemInput>;
  isRetail?: Maybe<Scalars['Boolean']>;
  isSandbox?: Maybe<Scalars['Boolean']>;
};

export type ManifestJobInputVehicleInput = {
  plate?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ManifestJobInputVendorInput = {
  ubi: Scalars['String'];
  licenseNumber: Scalars['String'];
  name: Scalars['String'];
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ManifestLineItemInput = {
  externalId?: Maybe<Scalars['String']>;
  complianceId: Scalars['String'];
  inventoryDescription?: Maybe<Scalars['String']>;
  qty: Scalars['Decimal'];
  subCategory?: Maybe<Scalars['String']>;
  isMedical: Scalars['Boolean'];
  unitOfMeasure?: Maybe<Scalars['String']>;
  inventoryName?: Maybe<Scalars['String']>;
  strain?: Maybe<Scalars['String']>;
  weightPerUnit?: Maybe<Scalars['Decimal']>;
  servingsPerUnitOrServingSize?: Maybe<Scalars['String']>;
};

export type NullableOfCcrsCsvUploadErrorTypeOperationFilterInput = {
  eq?: Maybe<CcrsCsvUploadErrorType>;
  neq?: Maybe<CcrsCsvUploadErrorType>;
  in?: Maybe<Array<Maybe<CcrsCsvUploadErrorType>>>;
  nin?: Maybe<Array<Maybe<CcrsCsvUploadErrorType>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type NullableOfMjfDisposalReasonTypeOperationFilterInput = {
  eq?: Maybe<MjfDisposalReasonType>;
  neq?: Maybe<MjfDisposalReasonType>;
  in?: Maybe<Array<Maybe<MjfDisposalReasonType>>>;
  nin?: Maybe<Array<Maybe<MjfDisposalReasonType>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type NullableOfMjfDisposalSourceTypeOperationFilterInput = {
  eq?: Maybe<MjfDisposalSourceType>;
  neq?: Maybe<MjfDisposalSourceType>;
  in?: Maybe<Array<Maybe<MjfDisposalSourceType>>>;
  nin?: Maybe<Array<Maybe<MjfDisposalSourceType>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type NullableOfPlantStageOperationFilterInput = {
  eq?: Maybe<PlantStage>;
  neq?: Maybe<PlantStage>;
  in?: Maybe<Array<Maybe<PlantStage>>>;
  nin?: Maybe<Array<Maybe<PlantStage>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type NullableOfUnitOfMeasureOperationFilterInput = {
  eq?: Maybe<UnitOfMeasure>;
  neq?: Maybe<UnitOfMeasure>;
  in?: Maybe<Array<Maybe<UnitOfMeasure>>>;
  nin?: Maybe<Array<Maybe<UnitOfMeasure>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type OrganizationFilterInput = {
  and?: Maybe<Array<OrganizationFilterInput>>;
  or?: Maybe<Array<OrganizationFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  organizationKey?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  stripeCustomerId?: Maybe<StringOperationFilterInput>;
  stripeSubscriptionId?: Maybe<StringOperationFilterInput>;
  stripePlanKey?: Maybe<StringOperationFilterInput>;
  websiteUrl?: Maybe<StringOperationFilterInput>;
  primaryPhone?: Maybe<StringOperationFilterInput>;
  primaryEmail?: Maybe<StringOperationFilterInput>;
  isVerified?: Maybe<BooleanOperationFilterInput>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  isGrowFlow?: Maybe<BooleanOperationFilterInput>;
  isDelinquent?: Maybe<BooleanOperationFilterInput>;
  isLost?: Maybe<BooleanOperationFilterInput>;
  isPaid?: Maybe<BooleanOperationFilterInput>;
  isPaidWithCheck?: Maybe<BooleanOperationFilterInput>;
  lastLoginDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  trialStartDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  trialEndDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  firstPaidDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  verifiedDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lostDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  note?: Maybe<StringOperationFilterInput>;
  organizationStatus?: Maybe<OrganizationStatusOperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSortInput = {
  id?: Maybe<SortEnumType>;
  organizationKey?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  stripeCustomerId?: Maybe<SortEnumType>;
  stripeSubscriptionId?: Maybe<SortEnumType>;
  stripePlanKey?: Maybe<SortEnumType>;
  websiteUrl?: Maybe<SortEnumType>;
  primaryPhone?: Maybe<SortEnumType>;
  primaryEmail?: Maybe<SortEnumType>;
  isVerified?: Maybe<SortEnumType>;
  isInactive?: Maybe<SortEnumType>;
  isGrowFlow?: Maybe<SortEnumType>;
  isDelinquent?: Maybe<SortEnumType>;
  isLost?: Maybe<SortEnumType>;
  isPaid?: Maybe<SortEnumType>;
  isPaidWithCheck?: Maybe<SortEnumType>;
  lastLoginDate?: Maybe<SortEnumType>;
  trialStartDate?: Maybe<SortEnumType>;
  trialEndDate?: Maybe<SortEnumType>;
  firstPaidDate?: Maybe<SortEnumType>;
  verifiedDate?: Maybe<SortEnumType>;
  lostDate?: Maybe<SortEnumType>;
  note?: Maybe<SortEnumType>;
  organizationStatus?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type OrganizationStatusOperationFilterInput = {
  eq?: Maybe<OrganizationStatus>;
  neq?: Maybe<OrganizationStatus>;
  in?: Maybe<Array<OrganizationStatus>>;
  nin?: Maybe<Array<OrganizationStatus>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PageSizesOperationFilterInput = {
  eq?: Maybe<PageSizes>;
  neq?: Maybe<PageSizes>;
  in?: Maybe<Array<PageSizes>>;
  nin?: Maybe<Array<PageSizes>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PlantFilterInput = {
  and?: Maybe<Array<PlantFilterInput>>;
  or?: Maybe<Array<PlantFilterInput>>;
  strain?: Maybe<StrainFilterInput>;
  wasteWeight?: Maybe<ComparableDecimalOperationFilterInput>;
  stage?: Maybe<NullableOfPlantStageOperationFilterInput>;
  lastPrinted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PlantSortInput = {
  strain?: Maybe<StrainSortInput>;
  wasteWeight?: Maybe<SortEnumType>;
  stage?: Maybe<SortEnumType>;
  lastPrinted?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type PrintNodeComputerFilterInput = {
  and?: Maybe<Array<PrintNodeComputerFilterInput>>;
  or?: Maybe<Array<PrintNodeComputerFilterInput>>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  inet?: Maybe<StringOperationFilterInput>;
  inet6?: Maybe<StringOperationFilterInput>;
  hostName?: Maybe<StringOperationFilterInput>;
  jre?: Maybe<StringOperationFilterInput>;
  createTimeStamp?: Maybe<ComparableDateTimeOperationFilterInput>;
  state?: Maybe<StringOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PrintNodePrinterCapabilitiesFilterInput = {
  and?: Maybe<Array<PrintNodePrinterCapabilitiesFilterInput>>;
  or?: Maybe<Array<PrintNodePrinterCapabilitiesFilterInput>>;
  bins?: Maybe<ListStringOperationFilterInput>;
  collate?: Maybe<BooleanOperationFilterInput>;
  copies?: Maybe<ComparableInt32OperationFilterInput>;
  color?: Maybe<BooleanOperationFilterInput>;
  dpis?: Maybe<ListStringOperationFilterInput>;
  medias?: Maybe<ListStringOperationFilterInput>;
  nup?: Maybe<ListComparableInt32OperationFilterInput>;
  supportsCustomPaperSize?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PrintNodePrinterFilterInput = {
  and?: Maybe<Array<PrintNodePrinterFilterInput>>;
  or?: Maybe<Array<PrintNodePrinterFilterInput>>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
  computer?: Maybe<PrintNodeComputerFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  capabilities?: Maybe<PrintNodePrinterCapabilitiesFilterInput>;
  default?: Maybe<BooleanOperationFilterInput>;
  createTimeStamp?: Maybe<ComparableDateTimeOperationFilterInput>;
  state?: Maybe<StringOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ProductAttributeFilterInput = {
  and?: Maybe<Array<ProductAttributeFilterInput>>;
  or?: Maybe<Array<ProductAttributeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  order?: Maybe<ComparableInt32OperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ProductAttributeSortInput = {
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  order?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type ProductAttributeTermFilterInput = {
  and?: Maybe<Array<ProductAttributeTermFilterInput>>;
  or?: Maybe<Array<ProductAttributeTermFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  order?: Maybe<ComparableInt32OperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ProductAttributeTermSortInput = {
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  order?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type ProductFilterInput = {
  and?: Maybe<Array<ProductFilterInput>>;
  or?: Maybe<Array<ProductFilterInput>>;
  inventoryType?: Maybe<InventoryTypeFilterInput>;
  category?: Maybe<CategoryFilterInput>;
  brand?: Maybe<BrandFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  traceableName?: Maybe<StringOperationFilterInput>;
  label?: Maybe<LabelFilterInput>;
  labelId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  strain?: Maybe<StrainFilterInput>;
  unit?: Maybe<UnitOfMeasureOperationFilterInput>;
  size?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ProductSortInput = {
  inventoryType?: Maybe<InventoryTypeSortInput>;
  category?: Maybe<CategorySortInput>;
  brand?: Maybe<BrandSortInput>;
  name?: Maybe<SortEnumType>;
  traceableName?: Maybe<SortEnumType>;
  label?: Maybe<LabelSortInput>;
  labelId?: Maybe<SortEnumType>;
  strain?: Maybe<StrainSortInput>;
  unit?: Maybe<SortEnumType>;
  size?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type PurchaseOrderFilterInput = {
  and?: Maybe<Array<PurchaseOrderFilterInput>>;
  or?: Maybe<Array<PurchaseOrderFilterInput>>;
  poNumber?: Maybe<ComparableInt32OperationFilterInput>;
  manifestComplianceId?: Maybe<StringOperationFilterInput>;
  stage?: Maybe<PurchaseOrderStageOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  vendor?: Maybe<VendorFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderSortInput = {
  poNumber?: Maybe<SortEnumType>;
  manifestComplianceId?: Maybe<SortEnumType>;
  stage?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
  vendor?: Maybe<VendorSortInput>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
};

export type PurchaseOrderStageOperationFilterInput = {
  eq?: Maybe<PurchaseOrderStage>;
  neq?: Maybe<PurchaseOrderStage>;
  in?: Maybe<Array<PurchaseOrderStage>>;
  nin?: Maybe<Array<PurchaseOrderStage>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type QuantityTypeOperationFilterInput = {
  eq?: Maybe<QuantityType>;
  neq?: Maybe<QuantityType>;
  in?: Maybe<Array<QuantityType>>;
  nin?: Maybe<Array<QuantityType>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ReconcileErrorsEventInput = {
  asOfDateTime?: Maybe<Scalars['DateTime']>;
  ignoreAsOfDate?: Maybe<Scalars['Boolean']>;
  applicationScope?: Maybe<CcrsApplicationScope>;
  licenseNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ccrsFileNames?: Maybe<Array<CcrsFileName>>;
};

export type RoomCreateDtoInput = {
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  purpose?: Maybe<SiteType>;
  traceabilityRoomTypeId?: Maybe<Scalars['Int']>;
};

export type RoomFilterInput = {
  and?: Maybe<Array<RoomFilterInput>>;
  or?: Maybe<Array<RoomFilterInput>>;
  purpose?: Maybe<SiteTypeOperationFilterInput>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  isPlant?: Maybe<BooleanOperationFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  roomTables?: Maybe<ListFilterInputTypeOfRoomTableFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type RoomSortInput = {
  purpose?: Maybe<SortEnumType>;
  isInactive?: Maybe<SortEnumType>;
  isPlant?: Maybe<SortEnumType>;
  isDefault?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type RoomTableCreateDtoInput = {
  rows?: Maybe<Scalars['Int']>;
  cols?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoomTableType>;
  notes?: Maybe<Scalars['String']>;
  roomId: Scalars['Int'];
};

export type RoomTableDtoInput = {
  id: Scalars['Int'];
  rows?: Maybe<Scalars['Int']>;
  cols?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoomTableType>;
  notes?: Maybe<Scalars['String']>;
  roomId: Scalars['Int'];
};

export type RoomTableFilterInput = {
  and?: Maybe<Array<RoomTableFilterInput>>;
  or?: Maybe<Array<RoomTableFilterInput>>;
  roomId?: Maybe<ComparableInt32OperationFilterInput>;
  rows?: Maybe<ComparableInt32OperationFilterInput>;
  cols?: Maybe<ComparableInt32OperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  type?: Maybe<RoomTableTypeOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type RoomTableSortInput = {
  roomId?: Maybe<SortEnumType>;
  rows?: Maybe<SortEnumType>;
  cols?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type RoomTableTypeOperationFilterInput = {
  eq?: Maybe<RoomTableType>;
  neq?: Maybe<RoomTableType>;
  in?: Maybe<Array<RoomTableType>>;
  nin?: Maybe<Array<RoomTableType>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type RoomUpdateDtoInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  purpose?: Maybe<SiteType>;
  traceabilityRoomTypeId?: Maybe<Scalars['Int']>;
};

export type SiteTypeOperationFilterInput = {
  eq?: Maybe<SiteType>;
  neq?: Maybe<SiteType>;
  in?: Maybe<Array<SiteType>>;
  nin?: Maybe<Array<SiteType>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StoreFrontFilterInput = {
  and?: Maybe<Array<StoreFrontFilterInput>>;
  or?: Maybe<Array<StoreFrontFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StoreFrontPermissionFilterInput = {
  and?: Maybe<Array<StoreFrontPermissionFilterInput>>;
  or?: Maybe<Array<StoreFrontPermissionFilterInput>>;
  storeFrontUserVendorId?: Maybe<ComparableInt32OperationFilterInput>;
  storeFrontUserVendor?: Maybe<StoreFrontUserVendorFilterInput>;
  storeFrontId?: Maybe<ComparableInt32OperationFilterInput>;
  storeFront?: Maybe<StoreFrontFilterInput>;
  isVerified?: Maybe<BooleanOperationFilterInput>;
  verifiedDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  buyerFirstName?: Maybe<StringOperationFilterInput>;
  buyerLastName?: Maybe<StringOperationFilterInput>;
  buyerPhone?: Maybe<StringOperationFilterInput>;
  isUserFavorite?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StoreFrontSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type StoreFrontUserVendorFilterInput = {
  and?: Maybe<Array<StoreFrontUserVendorFilterInput>>;
  or?: Maybe<Array<StoreFrontUserVendorFilterInput>>;
  userId?: Maybe<StringOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  buyerVendorId?: Maybe<ComparableInt32OperationFilterInput>;
  buyerVendor?: Maybe<VendorFilterInput>;
  storeFrontPermissions?: Maybe<ListFilterInputTypeOfStoreFrontPermissionFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StrainCreateDtoInput = {
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  strainType?: Maybe<StrainType>;
  sativaPercentage?: Maybe<Scalars['Decimal']>;
  indicaPercentage?: Maybe<Scalars['Decimal']>;
};

export type StrainFilterInput = {
  and?: Maybe<Array<StrainFilterInput>>;
  or?: Maybe<Array<StrainFilterInput>>;
  isInactive?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  strainType?: Maybe<StrainTypeOperationFilterInput>;
  sativaPercentage?: Maybe<ComparableDecimalOperationFilterInput>;
  indicaPercentage?: Maybe<ComparableDecimalOperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StrainSortInput = {
  isInactive?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  strainType?: Maybe<SortEnumType>;
  sativaPercentage?: Maybe<SortEnumType>;
  indicaPercentage?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type StrainTypeOperationFilterInput = {
  eq?: Maybe<StrainType>;
  neq?: Maybe<StrainType>;
  in?: Maybe<Array<StrainType>>;
  nin?: Maybe<Array<StrainType>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type StrainUpdateDtoInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  strainType?: Maybe<StrainType>;
  sativaPercentage?: Maybe<Scalars['Decimal']>;
  indicaPercentage?: Maybe<Scalars['Decimal']>;
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type SubmitCcrsReportEventInput = {
  asOfDateTime?: Maybe<Scalars['DateTime']>;
  applicationScope: CcrsApplicationScope;
  licenseNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  ccrsFileNames?: Maybe<Array<CcrsFileName>>;
  isSandbox?: Maybe<Scalars['Boolean']>;
};

export type SyncStatusOperationFilterInput = {
  eq?: Maybe<SyncStatus>;
  neq?: Maybe<SyncStatus>;
  in?: Maybe<Array<SyncStatus>>;
  nin?: Maybe<Array<SyncStatus>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type TraceabilityRoomTypeFilterInput = {
  and?: Maybe<Array<TraceabilityRoomTypeFilterInput>>;
  or?: Maybe<Array<TraceabilityRoomTypeFilterInput>>;
  isForPlantBatches?: Maybe<BooleanOperationFilterInput>;
  isForPlants?: Maybe<BooleanOperationFilterInput>;
  isForHarvests?: Maybe<BooleanOperationFilterInput>;
  isForPackages?: Maybe<BooleanOperationFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type TraceabilityRoomTypeSortInput = {
  isForPlantBatches?: Maybe<SortEnumType>;
  isForPlants?: Maybe<SortEnumType>;
  isForHarvests?: Maybe<SortEnumType>;
  isForPackages?: Maybe<SortEnumType>;
  isDefault?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type TypeOfJurisdictionFilterInput = {
  and?: Maybe<Array<TypeOfJurisdictionFilterInput>>;
  or?: Maybe<Array<TypeOfJurisdictionFilterInput>>;
  id?: Maybe<JurisdictionOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  regionName?: Maybe<StringOperationFilterInput>;
  regionAbbreviation?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  complianceProvider?: Maybe<ComplianceProviderFilterInput>;
  traceabilityUrl?: Maybe<StringOperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type TypeOfJurisdictionSortInput = {
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  regionName?: Maybe<SortEnumType>;
  regionAbbreviation?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  complianceProvider?: Maybe<ComplianceProviderSortInput>;
  traceabilityUrl?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type TypeOfReportFilterInput = {
  and?: Maybe<Array<TypeOfReportFilterInput>>;
  or?: Maybe<Array<TypeOfReportFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  group?: Maybe<StringOperationFilterInput>;
  key?: Maybe<StringOperationFilterInput>;
  shortName?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type TypeOfReportSortInput = {
  name?: Maybe<SortEnumType>;
  group?: Maybe<SortEnumType>;
  key?: Maybe<SortEnumType>;
  shortName?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type UnitOfMeasureOperationFilterInput = {
  eq?: Maybe<UnitOfMeasure>;
  neq?: Maybe<UnitOfMeasure>;
  in?: Maybe<Array<UnitOfMeasure>>;
  nin?: Maybe<Array<UnitOfMeasure>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['Uuid'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
};

export type UserFilterInput = {
  and?: Maybe<Array<UserFilterInput>>;
  or?: Maybe<Array<UserFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lastLogin?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  isGrowFlow?: Maybe<BooleanOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  employeeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  image?: Maybe<ImageFilterInput>;
  imageId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  accounts?: Maybe<ListFilterInputTypeOfAccountUserFilterInput>;
  storeComplianceId?: Maybe<StringOperationFilterInput>;
  storeVerified?: Maybe<BooleanOperationFilterInput>;
  storeFrontVendors?: Maybe<ListFilterInputTypeOfStoreFrontUserVendorFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  emailConfirmed?: Maybe<BooleanOperationFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: Maybe<BooleanOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  twoFactorEnabled?: Maybe<BooleanOperationFilterInput>;
  lockoutEnabled?: Maybe<BooleanOperationFilterInput>;
  accessFailedCount?: Maybe<ComparableInt32OperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type UserSortInput = {
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  lastLogin?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  isGrowFlow?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  employeeId?: Maybe<SortEnumType>;
  employee?: Maybe<EmployeeSortInput>;
  image?: Maybe<ImageSortInput>;
  imageId?: Maybe<SortEnumType>;
  storeComplianceId?: Maybe<SortEnumType>;
  storeVerified?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  emailConfirmed?: Maybe<SortEnumType>;
  phoneNumber?: Maybe<SortEnumType>;
  phoneNumberConfirmed?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  twoFactorEnabled?: Maybe<SortEnumType>;
  lockoutEnabled?: Maybe<SortEnumType>;
  accessFailedCount?: Maybe<SortEnumType>;
};

export type VehicleCreateDtoInput = {
  nickname?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  plate?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type VehicleFilterInput = {
  and?: Maybe<Array<VehicleFilterInput>>;
  or?: Maybe<Array<VehicleFilterInput>>;
  color?: Maybe<StringOperationFilterInput>;
  make?: Maybe<StringOperationFilterInput>;
  model?: Maybe<StringOperationFilterInput>;
  nickname?: Maybe<StringOperationFilterInput>;
  plate?: Maybe<StringOperationFilterInput>;
  vin?: Maybe<StringOperationFilterInput>;
  year?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  vendorId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  complianceId?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type VehicleSortInput = {
  color?: Maybe<SortEnumType>;
  make?: Maybe<SortEnumType>;
  model?: Maybe<SortEnumType>;
  nickname?: Maybe<SortEnumType>;
  plate?: Maybe<SortEnumType>;
  vin?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  vendorId?: Maybe<SortEnumType>;
  complianceId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export type VehicleUpdateDtoInput = {
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  plate?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type VendorFilterInput = {
  and?: Maybe<Array<VendorFilterInput>>;
  or?: Maybe<Array<VendorFilterInput>>;
  licenseNumber?: Maybe<StringOperationFilterInput>;
  jurisdiction?: Maybe<JurisdictionOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  globalId?: Maybe<StringOperationFilterInput>;
  line1?: Maybe<StringOperationFilterInput>;
  line2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  region?: Maybe<StringOperationFilterInput>;
  postalCode?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  qBCustomerId?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  createTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: Maybe<StringOperationFilterInput>;
  createBy?: Maybe<StringOperationFilterInput>;
  deleteBy?: Maybe<StringOperationFilterInput>;
  isDeleted?: Maybe<BooleanOperationFilterInput>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type VendorSortInput = {
  licenseNumber?: Maybe<SortEnumType>;
  jurisdiction?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  globalId?: Maybe<SortEnumType>;
  line1?: Maybe<SortEnumType>;
  line2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  region?: Maybe<SortEnumType>;
  postalCode?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  qBCustomerId?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createTimestamp?: Maybe<SortEnumType>;
  updateTimestamp?: Maybe<SortEnumType>;
  deleteTimestamp?: Maybe<SortEnumType>;
  updateBy?: Maybe<SortEnumType>;
  createBy?: Maybe<SortEnumType>;
  deleteBy?: Maybe<SortEnumType>;
  isDeleted?: Maybe<SortEnumType>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export enum CcrsApplicationScope {
  Unknown = 'UNKNOWN',
  Wholesale = 'WHOLESALE',
  Retail = 'RETAIL',
  Both = 'BOTH'
}

export enum CcrsCsvUploadErrorType {
  ErrorProcessingFailed = 'ERROR_PROCESSING_FAILED',
  AuthenticationError = 'AUTHENTICATION_ERROR',
  CcrsFailedProcesingFile = 'CCRS_FAILED_PROCESING_FILE'
}

export enum CcrsFileName {
  InventoryTransfer = 'INVENTORY_TRANSFER',
  Strain = 'STRAIN',
  Area = 'AREA',
  Product = 'PRODUCT',
  Inventory = 'INVENTORY',
  Plant = 'PLANT',
  Labtest = 'LABTEST',
  Sale = 'SALE',
  InventoryAdjustment = 'INVENTORY_ADJUSTMENT',
  PlantDestruction = 'PLANT_DESTRUCTION',
  PlantTransfer = 'PLANT_TRANSFER',
  Waste = 'WASTE',
  StrainsAll = 'STRAINS_ALL',
  AreaAccess = 'AREA_ACCESS',
  Manifest = 'MANIFEST'
}

export enum CcrsReportingCadence {
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export enum InventoryStatus {
  InStock = 'IN_STOCK',
  ScheduledForDestruction = 'SCHEDULED_FOR_DESTRUCTION',
  ScheduledForTransport = 'SCHEDULED_FOR_TRANSPORT',
  InTransit = 'IN_TRANSIT',
  InActive = 'IN_ACTIVE',
  Distributed = 'DISTRIBUTED',
  Destroyed = 'DESTROYED',
  Unknown = 'UNKNOWN'
}

export enum Jurisdiction {
  UsWaBioTrackRec = 'US_WA_BIO_TRACK_REC',
  UsWaMjfRec = 'US_WA_MJF_REC',
  UsNvMjfRec = 'US_NV_MJF_REC',
  UsIlBioTrackMed = 'US_IL_BIO_TRACK_MED',
  UsPaMjfRec = 'US_PA_MJF_REC',
  GfStandAlone = 'GF_STAND_ALONE',
  UsOrMetrc = 'US_OR_METRC',
  UsCaMetrc = 'US_CA_METRC',
  UsCoMetrc = 'US_CO_METRC',
  UsAkMetrc = 'US_AK_METRC',
  UsMdMetrc = 'US_MD_METRC',
  UsNvMetrc = 'US_NV_METRC',
  UsMtMetrc = 'US_MT_METRC',
  UsWaMjfTest = 'US_WA_MJF_TEST',
  UsMiMetrc = 'US_MI_METRC',
  UsAz = 'US_AZ',
  UsOk = 'US_OK',
  UsHemp = 'US_HEMP',
  UsMoMetrc = 'US_MO_METRC',
  UsMaMetrc = 'US_MA_METRC',
  UsArBioTrack = 'US_AR_BIO_TRACK',
  UsDeBioTrack = 'US_DE_BIO_TRACK',
  UsHiBioTrack = 'US_HI_BIO_TRACK',
  UsNhBioTrack = 'US_NH_BIO_TRACK',
  UsNmBioTrack = 'US_NM_BIO_TRACK',
  UsNyBioTrack = 'US_NY_BIO_TRACK',
  UsNdBioTrack = 'US_ND_BIO_TRACK',
  UsOhMetrc = 'US_OH_METRC',
  UsDcMetrc = 'US_DC_METRC',
  UsMeMetrc = 'US_ME_METRC',
  UsLaMetrc = 'US_LA_METRC',
  UsFl = 'US_FL',
  UsAl = 'US_AL',
  UsCt = 'US_CT',
  UsGa = 'US_GA',
  UsId = 'US_ID',
  UsIn = 'US_IN',
  UsIa = 'US_IA',
  UsKs = 'US_KS',
  UsKy = 'US_KY',
  UsMn = 'US_MN',
  UsMs = 'US_MS',
  UsNe = 'US_NE',
  UsNj = 'US_NJ',
  UsNc = 'US_NC',
  UsRi = 'US_RI',
  UsSc = 'US_SC',
  UsSdMetrc = 'US_SD_METRC',
  UsTn = 'US_TN',
  UsTx = 'US_TX',
  UsUt = 'US_UT',
  UsVt = 'US_VT',
  UsVa = 'US_VA',
  UsWvMetrc = 'US_WV_METRC',
  UsWi = 'US_WI',
  UsWy = 'US_WY',
  IsSandbox = 'IS_SANDBOX',
  All = 'ALL',
  E2E = 'E2E',
  MetrcBetaV2 = 'METRC_BETA_V2',
  Unknown = 'UNKNOWN'
}

export enum LabelType {
  Unknown = 'UNKNOWN',
  Plant = 'PLANT',
  Inventory = 'INVENTORY',
  Retail = 'RETAIL',
  Batch = 'BATCH',
  Disposal = 'DISPOSAL',
  EmployeeSample = 'EMPLOYEE_SAMPLE',
  OrderHeader = 'ORDER_HEADER',
  OrderHeaderItem = 'ORDER_HEADER_ITEM',
  EmployeeBadgeLabel = 'EMPLOYEE_BADGE_LABEL'
}

export enum LicenseGroup {
  Unknown = 'UNKNOWN',
  Producer = 'PRODUCER',
  Processor = 'PROCESSOR',
  Distributor = 'DISTRIBUTOR',
  MedicalRetail = 'MEDICAL_RETAIL',
  Transporter = 'TRANSPORTER',
  SpecialEvent = 'SPECIAL_EVENT',
  ProducerProcessor = 'PRODUCER_PROCESSOR',
  Lab = 'LAB',
  Retail = 'RETAIL',
  MicroBusiness = 'MICRO_BUSINESS',
  Tribal = 'TRIBAL'
}

export enum MjfBatchStatusType {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum MjfDisposalReasonType {
  Other = 'OTHER',
  Died = 'DIED',
  Infestation = 'INFESTATION',
  ProductReturned = 'PRODUCT_RETURNED',
  Spoilage = 'SPOILAGE',
  QualityControl = 'QUALITY_CONTROL',
  Mandated = 'MANDATED',
  FailedQa = 'FAILED_QA',
  Trim = 'TRIM',
  Damaged = 'DAMAGED',
  Cull = 'CULL',
  Expired = 'EXPIRED',
  DailyWaste = 'DAILY_WASTE',
  Waste = 'WASTE',
  Pruning = 'PRUNING'
}

export enum MjfDisposalSourceType {
  Batch = 'BATCH',
  Plant = 'PLANT',
  Inventory = 'INVENTORY',
  DailyPlantWaste = 'DAILY_PLANT_WASTE',
  PlantBatch = 'PLANT_BATCH',
  InventoryConversion = 'INVENTORY_CONVERSION'
}

export enum OrganizationStatus {
  Trial = 'TRIAL',
  TrialExpired = 'TRIAL_EXPIRED',
  Paid = 'PAID',
  Lost = 'LOST',
  PaidThenLost = 'PAID_THEN_LOST'
}

export enum PageSizes {
  Default = 'DEFAULT',
  Letter = 'LETTER',
  LetterLandscape = 'LETTER_LANDSCAPE',
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A4Landscape = 'A4_LANDSCAPE',
  SmallPaperback = 'SMALL_PAPERBACK',
  Legal = 'LEGAL',
  LegalLandscape = 'LEGAL_LANDSCAPE',
  Custom = 'CUSTOM'
}

export enum PlantStage {
  Inactive = 'INACTIVE',
  Vegging = 'VEGGING',
  Flowering = 'FLOWERING',
  Harvested = 'HARVESTED',
  Cured = 'CURED',
  Converted = 'CONVERTED',
  Seedling = 'SEEDLING',
  Clone = 'CLONE',
  ReadyToHarvest = 'READY_TO_HARVEST',
  Packaged = 'PACKAGED',
  Destroyed = 'DESTROYED',
  Transferred = 'TRANSFERRED',
  ScheduledForDisposal = 'SCHEDULED_FOR_DISPOSAL',
  Unknown = 'UNKNOWN'
}

export enum PurchaseOrderStage {
  Unknown = 'UNKNOWN',
  New = 'NEW',
  ReadyToPack = 'READY_TO_PACK',
  ReadyToLabel = 'READY_TO_LABEL',
  NeedToManifest = 'NEED_TO_MANIFEST',
  NeedToTransfer = 'NEED_TO_TRANSFER',
  InTransfer = 'IN_TRANSFER',
  Closed = 'CLOSED',
  Paid = 'PAID',
  Returned = 'RETURNED',
  Voided = 'VOIDED',
  Rejected = 'REJECTED',
  ExternalRequest = 'EXTERNAL_REQUEST',
  BackOrder = 'BACK_ORDER'
}

export enum QuantityType {
  WeightBased = 'WEIGHT_BASED',
  CountBased = 'COUNT_BASED',
  VolumeBased = 'VOLUME_BASED'
}

export enum RoomTableType {
  Default = 'DEFAULT'
}

export enum SiteType {
  Unknown = 'UNKNOWN',
  Clone = 'CLONE',
  Veg = 'VEG',
  Flower = 'FLOWER',
  Drying = 'DRYING',
  FlowerInv = 'FLOWER_INV',
  FlowerLots = 'FLOWER_LOTS',
  Quarantine = 'QUARANTINE',
  QuarantineForOrders = 'QUARANTINE_FOR_ORDERS',
  HideActivePackages = 'HIDE_ACTIVE_PACKAGES',
  DefaultInventory = 'DEFAULT_INVENTORY',
  DefaultPlant = 'DEFAULT_PLANT',
  UnknownInventory = 'UNKNOWN_INVENTORY',
  UnknownPlant = 'UNKNOWN_PLANT'
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StrainType {
  Hybrid = 'HYBRID',
  Indica = 'INDICA',
  Sativa = 'SATIVA',
  IndicaHybrid = 'INDICA_HYBRID',
  SativaHybrid = 'SATIVA_HYBRID',
  Cbd = 'CBD',
  CbdIndica = 'CBD_INDICA',
  CbdSativa = 'CBD_SATIVA'
}

export enum SyncStatus {
  Pending = 'PENDING',
  Error = 'ERROR',
  Complete = 'COMPLETE',
  Uploaded = 'UPLOADED',
  ProcessingErrors = 'PROCESSING_ERRORS',
  ErrorsReconciled = 'ERRORS_RECONCILED',
  ErrorProcessingFailed = 'ERROR_PROCESSING_FAILED',
  Reprocessing = 'REPROCESSING',
  AuthenticationError = 'AUTHENTICATION_ERROR',
  CcrsFailedProcesingFile = 'CCRS_FAILED_PROCESING_FILE'
}

export enum TraceabilitySyncStatus {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  Error = 'ERROR',
  Synced = 'SYNCED'
}

export enum UnitOfMeasure {
  Gm = 'GM',
  Mg = 'MG',
  Kg = 'KG',
  Oz = 'OZ',
  Lb = 'LB',
  Ea = 'EA',
  Gallon = 'GALLON',
  Liter = 'LITER',
  Milliliter = 'MILLILITER',
  FluidOunces = 'FLUID_OUNCES',
  Quarts = 'QUARTS',
  Pints = 'PINTS',
  MetricTons = 'METRIC_TONS',
  Unknown = 'UNKNOWN'
}




