import { isLocalBuild } from '@/environment';

interface Constants {
  ACCOUNT_ID: string;
  ADMIN_EMPLOYEES_FEATURE_FLAG: string;
  ADMIN_ROOMS_FEATURE_FLAG: string;
  ADMIN_STRAINS_FEATURE_FLAG: string;
  BULK_PRINTING_FEATURE_FLAG: string;
  CCRS_FEATURE_FLAG: string;
  DARK_MODE_FEATURE_FLAG: string;
  DEFAULT_JURISDICTION: string;
  DEFAULT_PAGE_SIZE: number;
  SEARCH_DEBOUNCE_TIMEOUT: number;
  JURISDICTION_ID: string;
  LAST_USED_LABEL_SEPARATOR: string;
  LAST_USED_PAGE_TEMPLATE: string;
  LAST_USED_PRINTER: string;
  PERPETUAL_INVENTORY_FEATURE_FLAG: string;
  ROUTE_AFTER_AUTHENTICATION: string;
  SIDER_COLLAPSED: string;
  THEME: string;
  TOKEN: string;
}

const constants: Constants = {
  ACCOUNT_ID: 'raptor_accountId',
  ADMIN_EMPLOYEES_FEATURE_FLAG: 'rap-admin-employees',
  ADMIN_ROOMS_FEATURE_FLAG: 'rap-admin-rooms',
  ADMIN_STRAINS_FEATURE_FLAG: 'rap-admin-strains',
  BULK_PRINTING_FEATURE_FLAG: 'raptor-bulk-print',
  CCRS_FEATURE_FLAG: 'ws-ccrs',
  DARK_MODE_FEATURE_FLAG: 'raptor-dark-mode',
  DEFAULT_JURISDICTION: 'ca',
  DEFAULT_PAGE_SIZE: isLocalBuild ? 10 : 30,
  SEARCH_DEBOUNCE_TIMEOUT: 250,
  JURISDICTION_ID: 'raptor_jurisdictionId',
  LAST_USED_LABEL_SEPARATOR: 'raptor_lastUsedLabelSeparator',
  LAST_USED_PAGE_TEMPLATE: 'raptor_lastUsedPageTemplate',
  LAST_USED_PRINTER: 'raptor_lastUsedPrinter',
  PERPETUAL_INVENTORY_FEATURE_FLAG: 'raptor-perpetual-inventory',
  ROUTE_AFTER_AUTHENTICATION: 'raptor_routeAfterAuthentication',
  SIDER_COLLAPSED: 'raptor_siderCollapsed',
  THEME: 'raptor_theme',
  TOKEN: 'token',
};

export default constants;
