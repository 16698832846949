import { gql } from '@apollo/client';

export const QUERY_MY_USER_ACCOUNT = gql`
  query getUser {    
     myAccount {
        id
        name
        region
        isVerified
        isStandAlone
        isGrowFlow
        isDisabled
        isInactive
        complianceCredential {
          id
          isSandbox
          complianceId
          licenseNumber
        }
        vendor {
          id
          licenseNumber

          licenseType {
            id
            name
            licenseGroupType
          }
          jurisdiction {
            id
            name
            regionAbbreviation
            regionName
            complianceProvider {
              id
              name
            }
          }
        }
        organization {
          id
          name
          organizationKey
          createTimestamp
        }
      }
      me {
        id
        firstName
        lastName
        email
        createTimestamp
      }
    }
  
`;
