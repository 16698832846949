/// <reference types="cypress" />

if (typeof Cypress !== 'undefined') {
  process.env = {
    ...process.env,
    ...Cypress.env()
  };
}

export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string;
export const AUTH0_CACHE_LOCATION = process.env.NEXT_PUBLIC_AUTH0_CACHE_LOCATION as 'memory' | 'localstorage' | undefined;
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string;
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const AUTH0_ISSUER = process.env.NEXT_PUBLIC_AUTH0_ISSUER as string;
export const BUILD_ENVIRONMENT = process.env.NEXT_PUBLIC_BUILD_ENVIRONMENT as string;
export const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string;
export const LAUNCH_DARKLY_CLIENT_ID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID as string;
export const LEGACY_HOST = process.env.NEXT_PUBLIC_LEGACY_HOST as string;
export const REPORTING_HOST = process.env.NEXT_PUBLIC_REPORTING_HOST as string;
export const SEGMENT_API_KEY = process.env.NEXT_PUBLIC_SEGMENT_API_KEY as string;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string;
export const VERSION = process.env.NEXT_PUBLIC_VERSION as string;

export const isLocalBuild = BUILD_ENVIRONMENT === 'development';
export const isProductionEnvironment = BUILD_ENVIRONMENT === 'production';
export const isDeployedEnvironment = !isLocalBuild;
